export const orderFilters = () => ({
        active: 'all',
        orderId: null,
        serviceName: null,
        travelerName: null,
        city: null,
        createdDateFrom: null,
        createdDateTo: null,
        serviceStartDateFrom: null,
        serviceStartDateTo: null,
        serviceEndDateFrom: null,
        serviceEndDateTo: null,
        serviceTypes: [],
        filterServices: false,
        processStatuses: [],
        customerPaymentStatuses: [],
        customerInvoiceStatuses: [],
        customerPenalty: [],
        supplier: null,
        supplierPaymentStatuses: [],
        supplierInvoiceStatuses: [],
        supplierPenalty: [],
        paymentMethod: [],
        customerCompany: null,
        modifiedDateFrom: null,
        modifiedDateTo: null,
        lastTicketingDateFrom: null,
        lastTicketingDateTo: null,
        reservationStatus: [],
        reservationName: null,
        reservationManager: null,
        serviceManager: null,
        ticketNumber: null,
        promoCode: null,
        loyaltyPointsMin: null,
        loyaltyPointsMax: null,
        clientType: null,
        crmPriority: null,
        agent: null,
        tripOrganizer: null,
        corporatePolicyViolated: null,
        waitAuthorize: false,
        noSupplierRef: false,
        supplierRef: null,
        channelManagerCode: null,
        chmPartnerBookingNumber: null,
        bookingMethod: [],
    }),
    ordersResponse = () => ({
        orders: [],
    })
