import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {filters, searchRequest, searchResponse} from '@/utils/extraServices/extra-services-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {extraServicesRuntimeStore, runtimeStore} from '@/store'
import Vue from 'vue'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            product: {offers: [], info: {}},
            info: {},
        }
    )
}

@Module({name: 'extraServices', stateFactory: true, namespaced: true})
export default class ExtraServiceStore extends ProductStoreBase {
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    filters = filters()
    sortFnName = 'priceAsc'
    searchExpirationTime = null
    prepareBookRequest = {}
    prepareBookResponse = {}
    basket = []
    bookingAdditionalOptions = []
    bookingExtraServices = []

    @Mutation
    ADD_TO_BASKET({info, offerKey}) {
        this.basket = this.basket.filter(item => {
            const offer = item.product.offers.find(offer => offer.offerKey === item.offerKey)
            return isBefore(new Date(), UTCToDate(parseISO(offer.expirationTime)))
        })
        const basketItem = this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            products: {offers: [], info: {}},
            info: {},
        }
        if (basketItem.offerKey) return
        const product = extraServicesRuntimeStore.offers.find(
            product => product.offers.findIndex(offer => offer.offerKey === offerKey) !== -1
        )
        this.basket.push({
            offerKey,
            product,
            info,
            searchRequest: this.searchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.product.offers.find(offer => offer.offerKey === offerKey)
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_FILTER(data) {
        this.filters[data.key] = data.value
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_SORT(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        Vue.set(this.prepareBookRequest, data.prop, data.val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.filters = filters()
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.prepareBookRequest = {}
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions = options
        }
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            //TODO API workaround - ignore params in API layer
            rq.tourists?.forEach(tourist => {
                if (tourist.passport) {
                    delete tourist.passport
                }
                if (tourist.citizenshipId) {
                    delete tourist.citizenshipId
                }
                if (tourist.phone) {
                    delete tourist.phone
                }
                if (tourist.email) {
                    delete tourist.email
                }
            })
            this.context.commit('SET_PREPARE_BOOK_REQUEST', rq)
            const rs = await appInstance.$api.prepareOwnExtraServiceBook.post(rq)
            this.context.commit('SET_PREPARE_BOOK_RESPONSE', rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @MutationAction({mutate: ['bookingAdditionalOptions', 'bookingExtraServices']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
            bookingExtraServices: [],
        }
    }

    @Action
    async getExtraServiceAdditionalOptions(offerKey) {
        try {
            const res = await appInstance.$api.ownExtraServiceAdditionalOptions.get({offerKey})
            this.context.commit('SET_BOOKING_ADDITIONAL_OPTIONS', res)
        } catch (error) {
            console.error(error)
        }
    }

    get isOffersExpired() {
        return () => this.hasOffers && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return !!this.searchResponse.products.length
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }
}
