import {Module, MutationAction, VuexModule} from 'vuex-module-decorators'
import {appInstance} from '@/utils/app-accessor'

@Module({name: 'adminRuntime', stateFactory: true, namespaced: true})
export default class AdminRuntimeStore extends VuexModule {
    notificationTemplates = []

    @MutationAction({mutate: ['notificationTemplates']})
    async loadNotificationTemplates() {
        const data = await appInstance.$api.notificationTemplates.get()
        return {notificationTemplates: data.templates}
    }
}
