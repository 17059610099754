import {deleteMethod, postMethod} from '~/api'

async function post(body) {
    return await postMethod('/api/orderServices', body)
}

async function remove(request) {
    return await deleteMethod('/proxy/orderServices', request)
}

export {post, remove}
