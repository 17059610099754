import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {persistentStore, extraServicesStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {searchResponse, searchRequest} from '@/utils/extraServices/extra-services-blank-states'
import {appInstance} from '@/utils/app-accessor'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'
import {onmessage} from '@/utils/worker-helpers'
import {PRODUCT_NAME} from '@/utils/extraServices/extra-services-const'
import ExtraServicesWorker from 'worker-loader!@/filters/extraServicesWorker'
import {newSearch, search} from '@/utils/store-helpers'

@Module({name: 'extraServicesRuntime', stateFactory: true, namespaced: true})
export default class ExtraServicesRuntime extends ProductRuntimeBase {
    city = {}

    @Mutation
    SET_CITY(val) {
        this.city = val
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @MutationAction({mutate: ['city']})
    async loadCity(id) {
        try {
            const {
                cities: [city],
            } = await appInstance.$api.locations.get({id, limitCities: 1})
            return {city}
        } catch (e) {
            return {city: {}}
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        onmessage.call(this, PRODUCT_NAME, new ExtraServicesWorker())
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest(), searchResponse(), extraServicesStore)
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        extraServicesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {
        if (this.city.id) await this.context.dispatch('loadCity', this.city.id)
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest(), searchResponse(), extraServicesStore)
        this.RESET()
        extraServicesStore.RESET()
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq, searchResponse(), extraServicesStore)
        EventBus.$emit(SEARCH_EVENT)
        await search.call(
            this,
            rq,
            PRODUCT_NAME,
            extraServicesStore,
            appInstance.$api.searchOwnExtraService,
            'products'
        )
        this.STOP_SEARCH()
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'extraServices', query}
        }
    }

    get infoPageLink() {
        return (product, searchRequest) => {
            const {query} = this.searchPageLink(searchRequest)
            query.productId = product.productId
            query.name = product.info.name
            return {name: 'extraService', query}
        }
    }

    get searchRequestFromQuery() {
        return query => {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.countryId = parseInt(rq.countryId, 10)
            rq.cityId = parseInt(rq.cityId, 10)
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            return rq
        }
    }
}
