export default function ({app}) {
    return {
        lang: {
            t: (key, ...params) => app.i18n.t(key, params),
        },
        theme: {
            options: {
                customProperties: true,
            },
            themes: {
                light: {
                    primary: '#62B7E5',
                    warning: '#E7BA74',
                    yellowlime: '#D8E562',
                    darkblue: '#1867C0',
                    black: '#333333',
                },
            },
        },
    }
}
