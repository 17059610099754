import {parseISO} from 'date-fns'

const priceTransformer = salesTerm => {
    const {
        price: {amount, currency, commission},
        originalAmount,
        originalCurrency,
    } = salesTerm
    return {
        ...originalAmountReduce({amount, currency, originalAmount, originalCurrency}),
        ...(commission && commission.amount && {commission}),
        loyaltyAmount: salesTerm.loyaltyAmount,
    }
}

const originalAmountReduce = ({amount, currency, originalCurrency, originalAmount}) => ({
    amount,
    currency,
    ...(originalCurrency && originalCurrency !== currency && {originalAmount, originalCurrency}),
})

const clientSalesTermTransform = clientSalesTerm => {
    const {
        discount: {percent, type, details} = {percent: 0},
        promoCodeApplied: promoCode,
        priceBreakDowns,
        price,
    } = clientSalesTerm
    let taxesAndFeesIncludedSum,
        taxesAndFeesExcluded = []
    if (priceBreakDowns && priceBreakDowns.length) {
        const filteredPriceBreakDowns = priceBreakDowns.filter(({name}) => name && name !== 'Service fee')
        if (filteredPriceBreakDowns.length) {
            taxesAndFeesIncludedSum = sumPrice(filteredPriceBreakDowns.map(({price}) => price))
        }
    } else if (price.taxesAndFees && price.taxesAndFees.length) {
        const taxesAndFeesIncluded = price.taxesAndFees.filter(({taxIncluded}) => taxIncluded)
        if (taxesAndFeesIncluded.length) {
            taxesAndFeesIncludedSum = sumPrice(taxesAndFeesIncluded)
        }
        taxesAndFeesExcluded = price.taxesAndFees.filter(({taxIncluded}) => !taxIncluded)
    }
    return {
        ...priceTransformer(clientSalesTerm),
        discount: percent,
        discountDetails: details,
        ...(type === 'HIDDEN' && {hiddenDiscount: true}),
        ...(promoCode && {promoCode}),
        taxesAndFeesIncludedSum,
        ...(taxesAndFeesExcluded.length && {taxesAndFeesExcluded}),
        ...(typeof price.serviceFee === 'object' &&
            !!price.serviceFee.amount && {serviceFee: originalAmountReduce(price.serviceFee)}),
    }
}

const getClientSalesTerm = salesTerms => {
    return salesTerms.find(el => el.type === 'CLIENT')
}

export const sumPrice = prices => {
    const [{currency, originalCurrency}] = prices
    return prices.reduce(
        (total, {amount, originalAmount}) => {
            total.amount += amount
            if (originalAmount) total.originalAmount += originalAmount
            return total
        },
        {
            currency,
            amount: 0,
            ...(originalCurrency && originalCurrency !== currency && {originalAmount: 0, originalCurrency}),
        }
    )
}

export const getCancellationLabel = (cancellations, price, nonRefundable) => {
    if (!cancellations || !cancellations.length) {
        return 'unknown'
    }

    if (nonRefundable) {
        return 'non_refundable'
    }

    const firstCondition = cancellations[0]

    if (firstCondition.dateFrom && firstCondition.price && price) {
        if (parseISO(firstCondition.dateFrom) <= new Date()) {
            if (firstCondition.price.amount < (price.originalAmount || price.amount)) {
                return 'special'
            }

            return 'non_refundable'
        } else {
            return 'free_cancellation'
        }
    }

    return 'unknown'
}

export const salesTermsRsTransformer = salesTerms => {
    if (!salesTerms) return {price: {}}
    const supplierSalesTerm = salesTerms.find(el => el.type === 'SUPPLIER'),
        clientSalesTerm = getClientSalesTerm(salesTerms)
    const marketPriceSalesTerm = salesTerms.find(el => el.type === 'MARKET')

    return {
        price: clientSalesTermTransform(clientSalesTerm),
        ...(supplierSalesTerm && {supplierPrice: priceTransformer(supplierSalesTerm)}),
        ...(marketPriceSalesTerm && {
            marketPrice: {...priceTransformer(marketPriceSalesTerm), source: marketPriceSalesTerm.source},
        }),
    }
}

export const salesTermPackagesRsTransformer = salesTermPackages => {
    //TODO delete empty objects after backend refactoring, now salesTermPackages can be undefined...
    const clientPrice = salesTermPackages.find(el => el.type === 'CLIENT') || {
        type: 'CLIENT',
        deltaPrice: {},
        notDeltaPrice: {},
    }
    const amount = clientPrice.deltaPrice?.amount
    const currency = clientPrice.deltaPrice?.currency
    const notDeltaPrice = clientPrice.notDeltaPrice
    const discountDetails = clientPrice.discountDetails

    const marketPrice = salesTermPackages?.find(el => el.type === 'MARKET') || {
        type: 'SUPPLIER',
        deltaPrice: {},
        notDeltaPrice: {},
    }

    return {
        deltaPrice: {amount, currency},
        marketPrice,
        ...(notDeltaPrice && {notDeltaPrice: {amount: notDeltaPrice.amount, currency: notDeltaPrice.currency}}),
        ...(discountDetails && {discountDetails}),
    }
}

export const setPriceFilter = (priceFilter, price) => {
    if (price.amount < priceFilter[0]) {
        priceFilter[0] = price.amount
    }
    if (price.amount > priceFilter[1]) {
        priceFilter[1] = price.amount
    }
}

export const flightSalesTermsRsTransformer = salesTerms => {
    if (!salesTerms) return {}
    const {priceDetails, ...clientSalesTerm} = getClientSalesTerm(salesTerms)
    return {
        price: clientSalesTermTransform(clientSalesTerm),
        priceDetails,
    }
}

export const isOwnProduct = supplierCode => supplierCode?.indexOf('company.') === 0
