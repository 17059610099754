import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {
    filters,
    searchRequest,
    searchResponse,
    searchScheduleResponse,
} from '~src/utils/flights/flights-blank-states.src'
import {appInstance} from '@/utils/app-accessor'
import {runtimeStore, flightsRuntimeStore} from '@/store'
import Vue from 'vue'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'

function getBasketItem(offerKey) {
    return this.basket.find(item => item.offer.offerKey === offerKey) || {searchRequest: searchRequest(), offer: {}}
}

@Module({name: 'flights', stateFactory: true, namespaced: true})
export default class FlightsStore extends ProductStoreBase {
    searchMode = 0
    searchRequest = searchRequest()
    scheduleSearchRequest = {partialResponse: false, ...searchRequest()}
    availabilityRequest = null

    searchResponse = searchResponse()
    searchScheduleResponse = searchScheduleResponse()
    availabilityResponse = searchResponse()

    sortFnName = 'priceAsc'
    searchExpirationTime = null

    filters = filters()
    scheduleFilters = [filters()]

    availableOfferFilters = filters()

    prepareBookRequest = {}
    prepareBookResponse = {}
    selectedScheduleItems = []

    basket = []
    bookingAdditionalOptions = []
    bookingExtraServices = []

    flightPreferences = null

    mealTypesSeatAllowance = []

    frequentFlyerCard = []

    airlines = []

    @MutationAction({mutate: ['airlines']})
    async loadAirlines() {
        try {
            const {airlines} = await appInstance.$api.airlines.get({})
            return {airlines}
        } catch (e) {
            return {airlines: []}
        }
    }

    @Mutation
    ADD_TO_BASKET(offer) {
        const {offerKey} = offer
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.offer.expirationTime))))
        if (getBasketItem.call(this, offer.offerKey).offerKey) return
        this.basket.push({
            offer,
            offerKey,
            searchRequest: flightsRuntimeStore.isPriceMode ? this.searchRequest : this.scheduleSearchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.offer
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_SCHEDULE_SEARCH_REQUEST(rq) {
        this.scheduleSearchRequest = rq
    }

    @Mutation
    SET_MEAL_TYPE_SEAT_ALLOWANCE({touristIndex, type, code}) {
        const alreadyGot = this.mealTypesSeatAllowance[touristIndex]
        Vue.set(this.mealTypesSeatAllowance, touristIndex, {...alreadyGot, ...{[type]: code}})
    }

    @Mutation
    DELETE_MEAL_TYPE_SEAT_ALLOWANCE({touristIndex, type}) {
        Vue.delete(this.mealTypesSeatAllowance[touristIndex], type)
    }

    @Mutation
    SET_FREQUENT_FLYER_CARD_NUMBER({index, code}) {
        let alreadyGot = this.frequentFlyerCard[index]
        if (alreadyGot) {
            alreadyGot.number = code
            Vue.set(this.frequentFlyerCard, index, {...alreadyGot})
        }
    }

    @Mutation
    DELETE_FREQUENT_FLYER_CARD_TYPE({index}) {
        Vue.delete(this.frequentFlyerCard, index)
    }

    @Mutation
    SET_FREQUENT_FLYER_CARD_TYPE({index, code}) {
        let alreadyGot = this.frequentFlyerCard[index]
        if (alreadyGot) {
            alreadyGot.airlineCode = code
        } else {
            alreadyGot = {
                number: '',
                airlineCode: code,
            }
        }
        Vue.set(this.frequentFlyerCard, index, {...alreadyGot})
    }

    @Mutation
    SET_AVAILABILITY_REQUEST(rq) {
        this.availabilityRequest = rq
    }

    @Mutation
    SET_SEARCH_MODE(val) {
        this.searchMode = val
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_SCHEDULE_FILTER({routeIndex, key, value}) {
        this.scheduleFilters[routeIndex][key] = value
    }

    @Mutation
    RESET_SCHEDULE_FILTERS(routeIndex) {
        Vue.set(this.scheduleFilters, routeIndex, {...filters(), duration: [0, 9000]})
    }

    @Mutation
    SET_AVAILABLE_OFFER_FILTER(data) {
        this.availableOfferFilters[data.key] = data.value
    }

    @Mutation
    RESET_AVAILABLE_OFFER_FILTERS() {
        this.availableOfferFilters = filters()
    }

    @Mutation
    SET_SORT(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_SELECTED_SCHEDULE_ITEM({index, offer, categoryClass}) {
        Vue.set(this.selectedScheduleItems, index, {offer, categoryClass})
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.filters = filters()
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.prepareBookRequest = {}
    }

    @Mutation
    NEW_SCHEDULE_SEARCH(searchRequest) {
        this.scheduleSearchRequest = searchRequest
        this.scheduleFilters = []
        searchRequest.routes.forEach(() => {
            this.scheduleFilters.push({...filters(), duration: [0, 9000]})
        })
        this.selectedScheduleItems = []
    }

    @Mutation
    SET_SEARCH_SCHEDULE_RESPONSE(searchResponse) {
        this.searchScheduleResponse = searchResponse
    }

    @Mutation
    SET_AVAILABILITY_RESPONSE(searchResponse) {
        this.availabilityResponse = searchResponse
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const rs = await appInstance.$api.prepareFlightBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    @Mutation
    SET_PREFERENCES_RESPONSE(searchResponse) {
        const {mealTypes, seatAllocations} = {...searchResponse}
        this.flightPreferences = {mealTypes, seatAllocations}
    }

    @Action
    async loadFlightPreferences(offerKey) {
        try {
            const rs = await appInstance.$api.flightPreferences.get({offerKey})
            this.SET_PREFERENCES_RESPONSE(rs)
        } catch (e) {
            this.flightPreferences = null
        }
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !flightsRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return !!this.searchResponse.offers.length
    }

    get hasScheduleOffers() {
        return this.searchScheduleResponse.routes.every(route => !!route.offers.length)
    }

    get hasAvailableOffers() {
        return !!this.availabilityResponse.offers.length
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions = options
        }
    }

    @MutationAction({
        mutate: ['bookingAdditionalOptions', 'bookingExtraServices', 'mealTypesSeatAllowance', 'frequentFlyerCard'],
    })
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
            bookingExtraServices: [],
            mealTypesSeatAllowance: [],
            frequentFlyerCard: [],
        }
    }

    @Action
    async getExtraServiceAdditionalOptions(offerKey) {
        try {
            const res = await appInstance.$api.additionalFlightOptions.get({offerKey})
            this.context.commit('SET_BOOKING_ADDITIONAL_OPTIONS', res)
        } catch (error) {
            console.error(error)
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }
}
