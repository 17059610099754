import {Mutation, VuexModule} from 'vuex-module-decorators'
import {convertPrice} from '@/utils/filters'
import {persistentStore} from '@/utils/store-accessor'

export default class ProductStoreBase extends VuexModule {
    searchPage = 1

    @Mutation
    SET_SEARCH_PAGE(page) {
        this.searchPage = page
    }

    @Mutation
    SET_SEARCH_RESPONSE(searchResponse) {
        this.searchResponse = searchResponse
    }

    get extraPrice() {
        return price => {
            return this.bookingExtraServices
                .filter(service => !service.mandatory)
                .reduce((total, {price, quantity}) => {
                    const serviceOriginalCurrency = price.originalCurrency || price.currency
                    let amount = convertPrice(price).amount,
                        serviceOriginalAmount = price.originalCurrency ? price.originalAmount : price.amount
                    if (quantity) {
                        amount = amount * quantity
                        serviceOriginalAmount = serviceOriginalAmount * quantity
                    }
                    total.amount += amount
                    total.supplierPriceAmount += serviceOriginalAmount
                    if (total.originalCurrency) {
                        if (serviceOriginalCurrency === total.originalCurrency) {
                            total.originalAmount += serviceOriginalAmount
                        }
                    }
                    return total
                }, price)
        }
    }

    get orderTotalPrice() {
        return ({price, currentPrice, supplierPrice}, prebooked, selectedAddOns = []) => {
            const offerPrice = prebooked && currentPrice ? currentPrice : price,
                originalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                originalAmount = offerPrice.originalAmount || offerPrice.amount

            const supplierPriceObj = supplierPrice?.originalAmount
                ? {amount: supplierPrice?.originalAmount, currency: supplierPrice?.originalCurrency}
                : {amount: supplierPrice?.amount, currency: supplierPrice?.currency}
            const convertedSupplierPrice = convertPrice(supplierPriceObj, persistentStore.getCurrency(originalCurrency))
            const convertedSupplierPriceOriginal = convertPrice(supplierPriceObj, originalCurrency)

            const totalOfferPrice = {
                amount: convertPrice(offerPrice).amount,
                currency: persistentStore.getCurrency(originalCurrency),
                originalAmount,
                originalCurrency,
                loyaltyAmount: price.loyaltyAmount,
                supplierPriceAmount: convertedSupplierPrice?.amount,
                supplierPriceOriginalCurrencyAmount: convertedSupplierPriceOriginal?.amount,
            }

            if (offerPrice?.commission) totalOfferPrice.commission = convertPrice(offerPrice.commission).amount
            if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                totalOfferPrice.supplierCommission =
                    supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount
            }
            for (const addOn of selectedAddOns.filter(({free}) => !free)) {
                const {price, supplierPrice, loyaltyPoints} = addOn.product[0].offers[0]
                totalOfferPrice.amount += convertPrice(price).amount
                if (originalCurrency === price.currency) {
                    totalOfferPrice.originalAmount += price.amount
                } else {
                    totalOfferPrice.originalAmount += convertPrice(price, originalCurrency).amount
                }
                if (price.commission) {
                    totalOfferPrice.commission += convertPrice(price.commission).amount
                }
                if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                    totalOfferPrice.supplierCommission =
                        supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount
                }
                totalOfferPrice.loyaltyPoints += loyaltyPoints || 0
                totalOfferPrice.loyaltyAmount += price.loyaltyAmount || 0
            }
            if (prebooked) return totalOfferPrice
            return this.extraPrice(totalOfferPrice)
        }
    }
}
