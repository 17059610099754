import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {additionalOptions, prepareAccommodationBook, tariffDetails} from '@/api'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {arrayEquals, UTCToDate} from '@/utils/helpers'
import {hotelsRuntimeStore, persistentStore, runtimeStore} from '@/store'
import {filters, groupOffersBy, searchRequest, searchResponse} from '~src/utils/hotels/hotels-blank-states.src'
import Vue from 'vue'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {convertPrice} from '@/utils/filters'

const singleHotelSearchResponse = () => ({
    offers: [],
})

@Module({name: 'hotels', stateFactory: true, namespaced: true})
export default class HotelsStore extends ProductStoreBase {
    /**
     * @type {{rooms: [string], partialResponse: boolean, convertToCurrency: string, endDate: string, cityId: null, startDate: string}}
     */
    searchRequest = {}
    searchResponse = searchResponse()
    searchExpirationTime = null
    filters = filters()
    sortFnName = 'recommended'
    groupOffersBy = groupOffersBy
    singleHotelSearchRequest = null
    singleHotelSearchExpirationTime = null
    singleHotelSearchResponse = singleHotelSearchResponse()
    conditions = {}
    tariffDetails = {}
    tourists = []
    prepareBookRequest = []
    prepareBookResponse = {}
    offersToCompare = []
    bookingAdditionalOptions = []
    bookingExtraServices = []
    basket = []
    checkInOutExtraService = []
    mapPosition = {}
    recentHotels = []

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_SORT_FN_NAME(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_GROUP_OFFERS_BY(val) {
        this.groupOffersBy = val
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_REQUEST(rq) {
        this.singleHotelSearchRequest = rq
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_RESPONSE(rs) {
        this.singleHotelSearchResponse = rs
    }

    @Mutation
    SET_CONDITIONS(conditions) {
        Vue.set(this.conditions, conditions.offerKey, conditions)
    }

    @Mutation
    SET_TARIFF_DETAILS(tariffDetails) {
        this.tariffDetails[tariffDetails.offerKey] = tariffDetails
    }

    @Mutation
    SET_TOURIST_PROP({roomIndex, index, prop, val}) {
        Vue.set(this.tourists[roomIndex][index], prop, val)
    }

    @Mutation
    SET_TOURIST_PASSPORT_PROP({roomIndex, index, prop, val}) {
        Vue.set(this.tourists[roomIndex][index].passport, prop, val)
    }

    @Mutation
    SET_TOURIST({roomIndex, index, val}) {
        if (!this.tourists[roomIndex]) {
            Vue.set(this.tourists, roomIndex, [])
        }
        Vue.set(this.tourists[roomIndex], index, val)
    }

    @Mutation
    SET_TOURISTS(tourists) {
        this.tourists = tourists
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP({index, prop, val}) {
        if (!this.prepareBookRequest[index]) {
            Vue.set(this.prepareBookRequest, index, {})
        }
        Vue.set(this.prepareBookRequest[index], prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_SINGLE_HOTEL_SEARCH_EXPIRATION_TIME(date) {
        this.singleHotelSearchExpirationTime = date
    }

    @Mutation
    SELECT_TO_COMPARE(hotelCode) {
        const index = this.offersToCompare.findIndex(hotel => hotel.hotelCode === hotelCode)
        if (index !== -1) {
            this.offersToCompare.splice(index, 1)
        } else {
            this.offersToCompare.push({hotelCode, rooms: []})
        }
    }

    @Mutation
    ADD_ROOM_TO_COMPARE({hotelCode, rooms}) {
        const index = this.offersToCompare.findIndex(hotel => hotel.hotelCode === hotelCode)
        if (index !== -1) {
            this.offersToCompare[index].rooms = rooms
        } else {
            this.offersToCompare.push({hotelCode, rooms})
        }
    }

    @Mutation
    CLEAR_OFFERS_TO_COMPARE() {
        this.offersToCompare = []
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions.push(options)
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }

    @Mutation
    SET_CHECK_IN_OUT_EXTRA_SERVICE({roomIndex, type, time, serviceRPH, price, convertedPrice}) {
        const alreadyGot = this.checkInOutExtraService[roomIndex] || {}
        const extraService = {
            [type]: {
                serviceRPH,
                time,
                price,
                convertedPrice,
                roomIndex,
                text: type,
            },
        }
        Vue.set(this.checkInOutExtraService, roomIndex, {...alreadyGot, ...extraService})
    }

    @Mutation
    DELETE_CHECK_IN_OUT_EXTRA_SERVICE({roomIndex, type}) {
        Vue.delete(this.checkInOutExtraService[roomIndex], type)
    }

    @Mutation
    ADD_TO_BASKET(offerKeys) {
        this.basket = this.basket.filter(item => isBefore(new Date(), UTCToDate(parseISO(item.offer.expirationTime))))
        if (this.basket.findIndex(item => arrayEquals(item.offerKeys, offerKeys)) !== -1) return
        const roomOffers = [],
            agreedOfferKeys = []
        const findHotelAndRoomOffers = offers => {
            return offers.find(offer => {
                offer.rooms.forEach(room => {
                    offerKeys.forEach(offerKey => {
                        const selectedRoomOffer = room.groupedOffers.find(
                            groupedOffer => groupedOffer.offerKey === offerKey
                        )
                        if (selectedRoomOffer) {
                            const index = selectedRoomOffer.rph - 1
                            roomOffers[index] = room
                            agreedOfferKeys[index] = offerKey
                        }
                    })
                })
                return !!roomOffers.length
            })
        }
        let offer, searchRequest
        if (this.singleHotelSearchResponse) {
            offer = findHotelAndRoomOffers(this.singleHotelSearchResponse.offers)
        }
        if (offer) {
            searchRequest = Object.assign({}, this.singleHotelSearchRequest)
        } else {
            //TODO For HDI need load offers from cache
            offer = findHotelAndRoomOffers(hotelsRuntimeStore.filteredOffers)
            searchRequest = Object.assign({}, this.searchRequest)
        }
        this.basket.push({offerKeys: agreedOfferKeys, offer, roomOffers, searchRequest})
    }

    //TODO Need refactoring with other products
    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = this.basket.find(item => item.offerKeys.includes(offerKey))
        if (!basketItem) return
        const roomOffer = basketItem.roomOffers.find(
            room => room.groupedOffers.findIndex(groupedOffer => groupedOffer.offerKey === offerKey) !== -1
        )
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(roomOffer, 'currentPrice', price)
    }

    @Mutation
    REFRESH_BASKET_ROOM_OFFERS({index, roomOffers, offerKeys, offer}) {
        const {searchRequest} = this.basket[index]
        this.basket.push({
            offer,
            searchRequest,
            roomOffers,
            offerKeys,
        })
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.offersToCompare = []
        this.filters = filters()
        this.searchExpirationTime = null
        this.mapPosition = {}
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.singleHotelSearchRequest = null
        this.tourists = []
        this.mapPosition = {}
        this.prepareBookRequest = []
    }

    @Mutation
    SET_MAP_POSITION(data) {
        this.mapPosition = data
    }

    @Mutation
    NEW_SINGLE_HOTEL_SEARCH(searchRequest) {
        this.singleHotelSearchRequest = searchRequest
        this.singleHotelSearchResponse = singleHotelSearchResponse()
        this.singleHotelSearchExpirationTime = null
    }

    @Mutation
    SET_RECENT_HOTELS(val) {
        const recentHotels = this.recentHotels.reverse()
        const hotelIndex = recentHotels.findIndex(hotel => hotel.hotelCode === val.hotelCode)

        if (hotelIndex === -1) recentHotels.push(val)
        else recentHotels[hotelIndex] = val

        this.recentHotels = recentHotels.reverse().slice(0, 5)
    }

    @MutationAction({mutate: ['bookingAdditionalOptions', 'bookingExtraServices', 'checkInOutExtraService']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
            bookingExtraServices: [],
            checkInOutExtraService: [],
        }
    }

    @Action
    async getTariffDetails(rq) {
        let data = {
            offerKey: rq.offerKey,
            loading: true,
        }
        try {
            this.context.commit('SET_TARIFF_DETAILS', data)
            const rs = await tariffDetails.get(rq)
            Object.assign(data, rs, {loading: false})
            this.context.commit('SET_TARIFF_DETAILS', data)
        } catch (e) {
            Object.assign(data, {
                loading: false,
                errors: ['Offer was expired'],
            })
            this.context.commit('SET_TARIFF_DETAILS', data)
        }
    }

    @Action({rawError: true})
    async prepareBook(rq) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(rq)
            const rs = await prepareAccommodationBook.post(rq)
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    //TODO Move to runtime
    @Action
    async loadExtraMealTypes({offerKey}) {
        try {
            const rs = await additionalOptions.get({offerKey})
            this.SET_BOOKING_ADDITIONAL_OPTIONS(rs)
        } catch (error) {
            console.error(error)
        }
    }

    @Action
    serverInit() {
        this.SET_SEARCH_REQUEST(searchRequest())
    }

    get extraMealTypes() {
        return this.bookingAdditionalOptions.length
            ? this.bookingAdditionalOptions.map(options => options.alternativeMeals || [])
            : []
    }

    get searchRequestTourists() {
        return hotelsRuntimeStore.searchRequestTouristsJSON(this.searchRequest)
    }

    get singleHotelSearchRequestTourists() {
        return this.singleHotelSearchRequest
            ? hotelsRuntimeStore.searchRequestTouristsJSON(this.singleHotelSearchRequest)
            : []
    }

    get hasSingleHotelOffers() {
        return !!this.singleHotelSearchResponse.offers.length
    }

    get basketItem() {
        return offerKeys => this.basket.find(item => arrayEquals(item.offerKeys, offerKeys)) || {}
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !hotelsRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get isSingleHotelOffersExpired() {
        return () =>
            this.hasSingleHotelOffers &&
            !hotelsRuntimeStore.singleHotelSearchActive &&
            isAfter(new Date(), this.singleHotelSearchExpirationTime)
    }

    get hasOffers() {
        return this.searchResponse.offersCount > 0
    }

    //TODO need refactoring with productStoreBase
    get orderTotalPrice() {
        return (roomOffers, prebooked, selectedAddOns = []) => {
            const originalCurrency = roomOffers[0].price.originalCurrency || roomOffers[0].price.currency
            const totalRoomsPrice = roomOffers.reduce(
                (total, {price, currentPrice, loyaltyPoints, supplierPrice}) => {
                    const offerPrice = prebooked && currentPrice ? currentPrice : price
                    total.amount += convertPrice(offerPrice).amount
                    if (offerPrice.commission) {
                        total.commission += convertPrice(offerPrice?.commission)?.amount
                    }
                    const roomOriginalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                        roomOriginalAmount = offerPrice.originalAmount || offerPrice.amount
                    total.loyaltyPoints += loyaltyPoints
                    if (roomOriginalCurrency === originalCurrency) {
                        total.originalAmount += roomOriginalAmount
                    }

                    const supplierPriceObj = supplierPrice?.originalAmount
                        ? {amount: supplierPrice?.originalAmount, currency: supplierPrice?.originalCurrency}
                        : {amount: supplierPrice?.amount, currency: supplierPrice?.currency}
                    const convertedSupplierPrice = convertPrice(
                        supplierPriceObj,
                        persistentStore.getCurrency(originalCurrency)
                    )
                    const convertedSupplierPriceOriginal = convertPrice(supplierPriceObj, originalCurrency)

                    total.supplierPriceAmount += convertedSupplierPrice?.amount
                    total.supplierPriceOriginalCurrencyAmount += convertedSupplierPriceOriginal?.amount

                    if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                        total.supplierCommission +=
                            supplierPrice?.commission.originalAmount || supplierPrice?.commission.amount
                    }
                    total.loyaltyAmount += price.loyaltyAmount
                    return total
                },
                {
                    amount: 0,
                    commission: 0,
                    currency: persistentStore.getCurrency(originalCurrency),
                    originalAmount: 0,
                    originalCurrency,
                    loyaltyPoints: 0,
                    supplierPriceAmount: 0,
                    supplierPriceOriginalCurrencyAmount: 0,
                    supplierCommission: 0,
                    loyaltyAmount: 0,
                }
            )
            for (const addOn of selectedAddOns.filter(({free}) => !free)) {
                const {price, loyaltyPoints, supplierPrice} = addOn.product[0].offers[0]
                totalRoomsPrice.amount += convertPrice(price).amount
                if (price.commission) {
                    totalRoomsPrice.commission += convertPrice(price.commission).amount
                }
                if (originalCurrency === price.currency) {
                    totalRoomsPrice.originalAmount += price.amount
                } else {
                    totalRoomsPrice.originalAmount += convertPrice(price, originalCurrency).amount
                }
                totalRoomsPrice.loyaltyPoints += loyaltyPoints || 0
                totalRoomsPrice.loyaltyAmount += price.loyaltyAmount || 0
                totalRoomsPrice.supplierPriceAmount += supplierPrice?.originalAmount || supplierPrice?.amount
                if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                    totalRoomsPrice.supplierCommission +=
                        supplierPrice?.commission.originalAmount || supplierPrice?.commission.amount
                }
            }
            if (prebooked) return totalRoomsPrice
            this.checkInOutPrice(totalRoomsPrice)
            return this.extraPrice(totalRoomsPrice)
        }
    }

    get extraServices() {
        return this.bookingAdditionalOptions.length
            ? this.bookingAdditionalOptions.map(options => options.extraServices || [])
            : []
    }

    get checkInOutPrice() {
        return total => {
            return this.checkInOutExtraService.reduce((total, {earlyCheckIn, lateCheckOut}) => {
                const serviceOriginalCurrencyIn = earlyCheckIn?.price?.currency
                const serviceOriginalCurrencyOut = lateCheckOut?.price?.currency
                const serviceOriginalCurrency = serviceOriginalCurrencyIn || serviceOriginalCurrencyOut

                const amountIn = earlyCheckIn?.price ? convertPrice(earlyCheckIn.price).amount : 0
                const amountOut = lateCheckOut?.price ? convertPrice(lateCheckOut.price).amount : 0

                const originalCheckInAmount = earlyCheckIn?.price?.amount || 0
                const originalCheckOutAmount = lateCheckOut?.price?.amount || 0

                total.amount = total.amount + amountIn + amountOut
                total.supplierPriceAmount += originalCheckInAmount + originalCheckOutAmount

                if (total.originalCurrency) {
                    if (serviceOriginalCurrency === total.originalCurrency) {
                        total.originalAmount = total.originalAmount + originalCheckInAmount + originalCheckOutAmount
                    }
                }
                return total
            }, total)
        }
    }

    get category() {
        return stdCategory => {
            switch (stdCategory) {
                case 'ONE':
                    return 1
                case 'ONE_AND_HALF':
                    return 1.5
                case 'TWO':
                    return 2
                case 'TWO_AND_HALF':
                    return 2.5
                case 'THREE':
                    return 3
                case 'THREE_AND_HALF':
                    return 3.5
                case 'FOUR':
                    return 4
                case 'FOUR_AND_HALF':
                    return 4.5
                case 'FIVE':
                    return 5
                default:
                    return null
            }
        }
    }
}
