export default {
    en: {
        popular: 'Popular',
        tour_region: 'Tour Region',
        hotels: 'Stays',
        hot: 'Hot',
        deals: 'Deals',
        we_have: 'We Have',
        best_offers_month: 'The Best Offers Every Month',
        customers: 'Customers',
        searches: 'Searches',
        bookings: 'Bookings',
        our_news: 'Our News',
        see_our_blog: 'See Our Blog',
        subscribe_newsletter: 'Subscribe newsletter',
        subscribe: 'Subscribe',
        get_latest_news: 'get latest news',
        our_partners: 'Our <span class="primary--text">Partners</span>',
        our: 'Our',
        partners: 'Partners',
        time_for_new: 'Book Maldives packages',
        adventures: 'Including transfer, meals and taxes',
        reasons_to: 'Reasons to',
        book_with_us: 'book with us',
        best_price: 'Best price',
        support_24: 'Support 24/7',
        easy_payment: 'Easy online payment',
        testimonials: 'Testimonials',
        read_more: 'Read More',
        enter_email: 'Enter your email address',
        your_question: 'Your question',
        could_not_find: 'Couldn’t find the',
        answer_to_question: 'answer to your question?',
        our_payments: 'Our payments',
        privacy_policy: 'Privacy Policy',
        privacy_policy_agree: 'By sending this form I confirm that I have read and accept Privacy Policy',
        sign_in: 'Sign in',
        sort: {
            sort: 'Sort by',
        },
        fully: 'Fully',
        see_details: 'See details',
        change_date: 'Change date',
        hotelRating: {
            excellent: 'Excellent',
            very_good: 'Very good',
            good: 'Good',
            satisfactory: 'Satisfactory',
            sufficient: 'Sufficient',
        },
        filter_by: 'Filter by',
        clear_filters: 'Clear filters',
        clear_compare: 'Clear selections',
        compare_page: 'Compare page',
        hide_room_offers: 'Hide offers',
        hide_details: 'Hide details',
        change_details: 'Change details',
        view_room_offers: 'View offers',
        filterHotelNamePlaceholder: 'Name',
        hide_map: 'Hide map',
        all_photos: 'All photos',
        room_for: 'Room for',
        room_details: 'Room details and photo',
        close_details: 'Close details',
        choose_room: 'Choose room',
        guest_details: 'Guest details',
        payment: 'Payment',
        confirm_booking: 'Confirm booking',
        passport_data: 'Personal Data',
        comment: 'Special Requests',
        type_here: 'Text',
        previous_step: 'Previous step',
        save_settings: 'Save settings',
        upload_photo: 'Upload photo',
        user_information: 'User Information',
        orders: 'My reservations',
        back_to_reservations: 'Back to reservation list',
        cancel_reservations: 'Cancel Reservations',
        load_more: 'Load more',
        page: 'Page',
        not_found: 'not found',
        let_help: 'Let us help you find your way back',
        back_to_homepage: 'Back to homepage',
        unknown_rating: 'Unknown rating',
        night_qty: 'night | nights',
    },
    ar: {
        popular: 'جمع',
        tour_region: 'منطقة الجولة',
        hotels: 'الإقامة',
        hot: 'حار',
        deals: 'صفقات',
        we_have: 'نملك',
        best_offers_month: 'أفضل العروض كل شهر',
        customers: 'عملاء',
        searches: 'عمليات البحث',
        bookings: 'الحجوزات',
        our_news: 'أخبارنا',
        see_our_blog: 'انظر مدونتنا',
        subscribe_newsletter: 'اشترك في النشرة الإخبارية',
        subscribe: 'الإشتراك',
        get_latest_news: 'احصل على آخر الأخبار',
        our_partners: 'شر<span class="primary--text">كاؤنا</span>',
        our: 'ملكنا',
        partners: 'شركاء',
        time_for_new: 'احجز بكجات المالديف',
        adventures: 'شاملةً المواصلات والوجبات والضرائب',
        reasons_to: 'أسباب',
        book_with_us: 'نتميز بتوفير',
        best_price: 'افضل سعر',
        support_24: 'دعم 24/7',
        easy_payment: 'سهولة الدفع عبر الإنترنت',
        testimonials: 'الشهادات - التوصيات',
        read_more: 'اقرأ أكثر',
        enter_email: 'أدخل عنوان بريدك الالكتروني',
        your_question: 'سؤالك',
        could_not_find: 'لديك استفسار ؟',
        answer_to_question: 'تواصل معنا',
        our_payments: 'مدفوعاتنا',
        privacy_policy: 'سياسة الخصوصية',
        privacy_policy_agree: 'بإرسال هذا النموذج أؤكد أنني قد قرأت ووافقت على سياسة الخصوصية',
        sort: {
            sort: 'ترتيب حسب',
        },
        fully: 'تماما',
        see_details: 'انظر التفاصيل',
        change_date: 'تغيير التاريخ',
        hotelRating: {
            excellent: 'ممتاز',
            very_good: 'جيد جدًا',
            good: 'حسنا!',
            satisfactory: 'مرض',
            sufficient: 'كافٍ',
        },
        filter_by: 'مصنف بواسطة',
        clear_filters: 'مسح عوامل التصفية',
        clear_compare: 'تحديدات واضحة',
        compare_page: 'قارن الصفحة',
        hide_details: 'أخف التفاصيل',
        change_details: 'تغيير التفاصيل',
        view_room_offers: 'مشاهدة العروض',
        filterHotelNamePlaceholder: 'اسم',
        hide_map: 'إخفاء الخريطة',
        all_photos: 'جميع الصور',
        room_for: 'غرفة ل',
        room_details: 'تفاصيل الغرفة والصورة',
        close_details: 'إغلاق التفاصيل',
        choose_room: 'اختر الغرفة',
        guest_details: 'تفاصيل الضيف',
        payment: 'دفع',
        confirm_booking: 'تأكيد الحجز',
        passport_data: 'بيانات شخصية',
        comment: 'طلبات خاصة',
        type_here: 'نص',
        previous_step: 'خطوة سابقة',
        save_settings: 'احفظ التغييرات',
        upload_photo: 'حمل الصورة',
        user_information: 'معلومات المستخدم',
        orders: 'تحفظاتي',
        back_to_reservations: 'العودة إلى قائمة الحجز',
        cancel_reservations: 'إلغاء الحجوزات',
        load_more: 'تحميل المزيد',
        page: 'صفحة',
        not_found: 'غير معثور عليه',
        let_help: 'دعنا نساعدك في العثور على طريقك للعودة',
        back_to_homepage: 'العودة إلى الصفحة الرئيسية',
        unknown_rating: 'تصنيف غير معروف',
        earlyCheckInTitle: 'دخول',
        lateCheckOutTitle: 'خروج',
        night_qty: 'عدد الليالي | عدد الليالي',
    },
}
