import Vue from 'vue'
import VueI18n from 'vue-i18n'
import vuetifyEn from 'vuetify/es5/locale/en'
import vuetifyEs from 'vuetify/es5/locale/es'
import vuetifyDe from 'vuetify/es5/locale/de'
import vuetifyRu from 'vuetify/es5/locale/ru'
import vuetifyAr from 'vuetify/es5/locale/ar'
import custom from '~src/locales/custom'

import dateFnsEn from 'date-fns/locale/en-GB'
import dateFnsAr from 'date-fns/locale/ar-SA'
import dateFnsRu from 'date-fns/locale/ru'
import dateFnsEs from 'date-fns/locale/es'
import dateFnsDe from 'date-fns/locale/de'

const deepmerge = require('deepmerge')

Vue.use(VueI18n)

export default ({app, req, $config, $dateFns}) => {
    $dateFns.localeOptions = {
        en: dateFnsEn.options,
        ar: dateFnsAr.options,
        ru: dateFnsRu.options,
        es: dateFnsEs.options,
        de: dateFnsDe.options,
    }

    const numberFormats = {
        'en-US': {
            currency: {
                style: 'currency',
                currency: 'USD',
            },
        },
        'en-UK': {
            currency: {
                style: 'currency',
                currency: 'EUR',
            },
        },
        'ru-RU': {
            currency: {
                style: 'currency',
                currency: 'RUB',
            },
        },
        'en-GB': {
            currency: {
                style: 'currency',
                currency: 'GBP',
            },
        },
    }

    // Set i18n instance on app

    VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
        if (this.locale !== 'ru') {
            if (choice === 0) {
                return 0
            }
            if (choice === 1) {
                return choicesLength < 3 ? 0 : 1
            }
            return choicesLength < 3 ? 1 : 2
        }

        if (choice === 0) {
            return 0
        }

        if (choice % 10 === 1 && choice % 100 !== 11) {
            return 1
        }

        if ([2, 3, 4].includes(choice % 10) && ![12, 13, 14].includes(choice % 100)) {
            return 2
        }

        if (![5, 6, 7, 8, 9].includes(choice % 10) || ![11, 12, 13, 14].includes(choice % 100)) {
            return choicesLength < 4 ? 2 : 3
        }

        // const teen = choice > 10 && choice < 20
        // const endsWithOne = choice % 10 === 1

        // if (!teen && endsWithOne) {
        //     return 1
        // }

        // if (!teen && custom) {
        //     return 2
        // }

        // return choicesLength < 4 ? 2 : 3
    }
    // This way we can use it in middleware and pages asyncData/fetch
    const en = require('~/locales/en.json'),
        es = require('~/locales/es.json'),
        de = require('~/locales/de.json'),
        ru = require('~/locales/ru.json'),
        ar = require('~/locales/ar.json')
    let messages = {en, ru, ar, es, de}
    Object.assign(en, {
        transfers: require('~/locales/transfers/en.json'),
        activities: require('~/locales/activities/en.json'),
        admin: require('~/locales/admin/en.json'),
        flights: require('~/locales/flights/en.json'),
        packages: require('~/locales/packages/en.json'),
        tours: require('~/locales/tours/en.json'),
        extraServices: require('~/locales/extraServices/en.json'),
        supplier: require('~/locales/supplier/en.json'),
        carsRent: require('~/locales/carsRent/en.json'),
        insurance: require('~/locales/insurance/en.json'),
        visa: require('~/locales/visa/en.json'),
        cruises: require('~/locales/cruises/en.json'),
        $vuetify: vuetifyEn,
    })
    Object.assign(es, {
        transfers: require('~/locales/transfers/es.json'),
        activities: require('~/locales/activities/es.json'),
        admin: require('~/locales/admin/es.json'),
        flights: require('~/locales/flights/es.json'),
        packages: require('~/locales/packages/es.json'),
        tours: require('~/locales/tours/es.json'),
        extraServices: require('~/locales/extraServices/es.json'),
        supplier: require('~/locales/supplier/es.json'),
        carsRent: require('~/locales/carsRent/es.json'),
        insurance: require('~/locales/insurance/es.json'),
        visa: require('~/locales/visa/es.json'),
        cruises: require('~/locales/cruises/es.json'),
        $vuetify: vuetifyEs,
    })
    Object.assign(de, {
        transfers: require('~/locales/transfers/de.json'),
        activities: require('~/locales/activities/de.json'),
        admin: require('~/locales/admin/de.json'),
        flights: require('~/locales/flights/de.json'),
        packages: require('~/locales/packages/de.json'),
        tours: require('~/locales/tours/de.json'),
        extraServices: require('~/locales/extraServices/de.json'),
        supplier: require('~/locales/supplier/de.json'),
        carsRent: require('~/locales/carsRent/de.json'),
        insurance: require('~/locales/insurance/de.json'),
        visa: require('~/locales/visa/de.json'),
        cruises: require('~/locales/cruises/de.json'),
        $vuetify: vuetifyDe,
    })
    Object.assign(ru, {
        transfers: require('~/locales/transfers/ru.json'),
        activities: require('~/locales/activities/ru.json'),
        admin: require('~/locales/admin/ru.json'),
        flights: require('~/locales/flights/ru.json'),
        packages: require('~/locales/packages/ru.json'),
        tours: require('~/locales/tours/ru.json'),
        extraServices: require('~/locales/extraServices/ru.json'),
        supplier: require('~/locales/supplier/ru.json'),
        carsRent: require('~/locales/carsRent/ru.json'),
        insurance: require('~/locales/insurance/ru.json'),
        visa: require('~/locales/visa/ru.json'),
        cruises: require('~/locales/cruises/ru.json'),
        $vuetify: vuetifyRu,
    })
    Object.assign(ar, {
        $vuetify: vuetifyAr,
        cruises: require('~/locales/cruises/ar.json'),
    })
    messages = deepmerge(messages, custom)
    messages = deepmerge(messages, {ru: {$vuetify: require('~/locales/vuetify-ru.json')}})
    let locale = Object.values($config.locales)[0]
    if (app.$cookies.get('locale')) {
        const language = app.$cookies.get('locale')
        if (Object.values($config.locales).includes(language)) {
            locale = language
        } else {
            app.$cookies.set('locale', locale)
        }
    } else {
        if (process.server && req.headers['accept-language']) {
            const language = req.headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2)
            if (Object.values($config.locales).includes(language)) {
                locale = language
            }
        }
        app.$cookies.set('locale', locale)
    }
    app.i18n = new VueI18n({
        locale,
        fallbackLocale: 'en',
        messages,
        numberFormats,
    })

    app.i18n.path = link => {
        if (app.i18n.locale === app.i18n.fallbackLocale) {
            return `/${link}`
        }
        return `/${app.i18n.locale}/${link}`
    }
}
