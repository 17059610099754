import {getMethod, postMethod, putMethod} from '~/api'

async function get(request) {
    return await getMethod('/api/companies', request)
}

async function post(request) {
    return await postMethod('/api/companies', request)
}

async function put(id, request) {
    return await putMethod(`/proxy/companies/${id}`, request)
}

export {get, post, put}
