import {getBase} from '~/api'
import {appInstance} from '@/utils/app-accessor'
import weatherRsTransformer from './weatherRsTransformer'

async function get({cityName, latitude, longitude, units = 'metric', lang = 'en-EN', cnt = 40}) {
    const key = appInstance.$config.openWeatherMapKey
    // const key = 'f23950cd180dc3faa31f51d59526c5fb'
    let url

    if (latitude && longitude)
        url = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&units=${units}&lang=${lang}&cnt=${cnt}&appid=${key}`
    else
        url = `https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&units=${units}&lang=${lang}&cnt=${cnt}&appid=${key}`

    const data = await getBase(url)

    return weatherRsTransformer(data)
}

export {get}
