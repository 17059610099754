import globalAccessor from '@/utils/global-accessor'
import {EventBus, FILTER_EVENT} from '@/utils/event-bus'

export const worker = (ctx, sortFn, filterFn, {data: {action, offers: data, filters, sortKey}}) => {
    if (action === 'load') {
        ctx.offers = data
    }
    console.time('filterWorker')
    ctx.postMessage(sortFn(filterFn(ctx.offers, filters), sortKey))
    console.timeEnd('filterWorker')
}

export const globalAccessorKey = productName => `${productName}Worker`

export function onmessage(productName, productWorker) {
    globalAccessor[globalAccessorKey(productName)] = productWorker
    globalAccessor[globalAccessorKey(productName)].onmessage = ({data}) => {
        this.STOP_FILTER()
        console.timeEnd('filter')
        if (this.filterActive) return
        this.SET_OFFERS(data)
        EventBus.$emit(FILTER_EVENT)
    }
}

export const postMessageLoad = (productName, productStore, offers) => {
    console.time('filter')
    globalAccessor[globalAccessorKey(productName)].postMessage({
        action: 'load',
        offers,
        filters: {...productStore.filters},
        sortKey: productStore.sortFnName,
    })
}

export const postMessageFilter = (productName, productStore) => {
    console.time('filter')
    globalAccessor[globalAccessorKey(productName)].postMessage({
        action: 'filter',
        filters: {...productStore.filters},
        sortKey: productStore.sortFnName,
    })
}
