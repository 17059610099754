import {Action, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators'
import {packagesStore, persistentStore} from '@/store'
import {
    EventBus,
    FILTER_EVENT,
    SEARCH_EVENT,
    PACKAGE_FLIGHT_SELECTED,
    RESET,
    RESET_BOOKING_DATA,
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
} from '@/utils/event-bus'
import {searchRequest, searchResponse} from '@/utils/packages/packages-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {addSeconds} from 'date-fns'
import Vue from 'vue'
import {axiosInstance} from '@/utils/axios-accessor'
import {matches} from '@/utils/helpers'
import {convertPrice} from '@/utils/filters'
import {initSliderFilter} from '@/utils/store-helpers'
import PackagesWorker from 'worker-loader!@/filters/packagesWorker'
import {globalAccessorKey} from '@/utils/worker-helpers'
import {PRODUCT_NAME as FLIGHTS_PRODUCT_NAME} from '@/utils/flights/flights-const'
import {PRODUCT_NAME as HOTELS_PRODUCT_NAME} from '@/utils/hotels/hotels-const'
import {PRODUCT_NAME} from '@/utils/packages/packages-const'
import globalAccessor from '@/utils/global-accessor'

const postMessageLoad = offers => {
    console.time('filter')
    globalAccessor[globalAccessorKey(PRODUCT_NAME)].postMessage({
        action: 'load',
        offers,
        flightSortKey: packagesStore.flightSortKey,
        hotelSortKey: packagesStore.hotelSortKey,
        flightFilters: packagesStore.flightFilters,
        hotelFilters: packagesStore.hotelFilters,
    })
}

//TODO Refactoring partial search with all products
async function search(rq) {
    const cancelTokenSource = axiosInstance.CancelToken.source()
    this.SET_SEARCH_CTS(cancelTokenSource)
    const searchResponse = await appInstance.$api.searchDynamicPackages.get(rq, cancelTokenSource.token)
    if (!searchResponse) return
    if (searchResponse.offers.flights.length && searchResponse.offers.hotels.length) {
        packagesStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), appInstance.$config.search.offersLifetime))
        const hotelOfferKey = searchResponse.selectedOffersInfo.find(el => el.productType === 'ACCOMMODATION').offerKey
        const hotel = searchResponse.offers.hotels.find(
            ({rooms}) => rooms.findIndex(({groupedOffers}) => groupedOffers[0].offerKey === hotelOfferKey) !== -1
        )
        const flightOfferKey = searchResponse.selectedOffersInfo.find(el => el.productType === 'FLIGHT').offerKey
        const flight = searchResponse.offers.flights.find(el => el.offerKey === flightOfferKey)
        packagesStore.SET_SELECTED_HOTEL({offerKey: hotelOfferKey, offer: hotel})
        packagesStore.SET_SELECTED_FLIGHT({offerKey: flightOfferKey, offer: flight})

        initSliderFilter(
            packagesStore.searchResponse.filters.hotels.price,
            packagesStore.hotelFilters.price,
            searchResponse.filters.hotels.price,
            'price',
            packagesStore.SET_HOTEL_FILTER
        )
        initSliderFilter(
            packagesStore.searchResponse.filters.flights.price,
            packagesStore.flightFilters.price,
            searchResponse.filters.flights.price,
            'price',
            packagesStore.SET_FLIGHT_FILTER
        )
        initSliderFilter(
            packagesStore.searchResponse.filters.flights.duration,
            packagesStore.flightFilters.duration,
            searchResponse.filters.flights.duration,
            'duration',
            packagesStore.SET_FLIGHT_FILTER
        )
    }

    const flights = searchResponse.offers.flights,
        hotels = searchResponse.offers.hotels,
        sr = {...searchResponse}
    sr.offers.flightsCount = flights.length
    sr.offers.hotelsCount = hotels.length
    packagesStore.SET_SEARCH_RESPONSE(sr)
    appInstance.$localForage.setItem(PRODUCT_NAME, {flights, hotels})
    postMessageLoad({flights, hotels})
    EventBus.$emit(PACKAGE_FLIGHT_SELECTED)
}

function newSearch(rq) {
    this.stopSearch()
    this.SET_FLIGHT_OFFERS([])
    this.SET_HOTEL_OFFERS([])
    packagesStore.SET_SEARCH_RESPONSE(searchResponse())
    packagesStore.NEW_SEARCH(rq)
}

@Module({name: 'packagesRuntime', stateFactory: true, namespaced: true})
export default class PackagesRuntimeStore extends VuexModule {
    arrivalPoint = {}
    departurePoint = {}

    updateActiveOffers = []
    searchActiveCount = 0
    filterActiveCount = 0
    searchCTS = null
    flightOffers = []
    hotelOffers = []

    @Mutation
    SET_ARRIVAL_POINT(val) {
        this.arrivalPoint = val
    }

    @Mutation
    SET_DEPARTURE_POINT(val) {
        this.departurePoint = val
    }

    @Mutation
    SET_OFFERS(val) {
        if (val.flights && val.hotels) {
            this.flightOffers = val.flights
            this.hotelOffers = val.hotels
        } else {
            this.flightOffers = []
            this.hotelOffers = []
        }
    }

    @Mutation
    SET_FLIGHT_OFFERS(val) {
        this.flightOffers = val
    }

    @Mutation
    SET_HOTEL_OFFERS(val) {
        this.hotelOffers = val
    }

    @Mutation
    START_SEARCH() {
        this.searchActiveCount++
    }

    @Mutation
    STOP_SEARCH() {
        this.searchActiveCount--
    }

    @Mutation
    START_FILTER() {
        this.filterActiveCount++
    }

    @Mutation
    STOP_FILTER() {
        this.filterActiveCount--
    }

    @Mutation
    SET_SEARCH_CTS(cancelTokenSource) {
        this.searchCTS = cancelTokenSource
    }

    @Mutation
    ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers.push({supplierCode, cityCode, hotelCode})
    }

    @Mutation
    REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode}) {
        this.updateActiveOffers = this.updateActiveOffers.filter(
            item => !matches(item, {supplierCode, cityCode, hotelCode})
        )
    }

    @Mutation
    UPDATE_ROOMS({supplierCode, cityCode, hotelCode, rooms}) {
        const hotel = this.hotelOffers.find(
            hotel => hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
        )
        hotel.rooms = rooms
        Vue.set(hotel, 'updatedRooms', true)
    }

    @MutationAction({mutate: ['arrivalPoint', 'departurePoint']})
    async loadPoints({departureCityId, arrivalCityId}) {
        try {
            const points = await Promise.all([
                appInstance.$api.locations.get({id: departureCityId, limitCities: 1}),
                appInstance.$api.locations.get({id: arrivalCityId, limitCities: 1}),
            ])
            return {
                departurePoint: points[0].cities[0],
                arrivalPoint: points[1].cities[0],
            }
        } catch (e) {
            return {
                departurePoint: null,
                arrivalPoint: null,
            }
        }
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)

        globalAccessor[globalAccessorKey(PRODUCT_NAME)] = new PackagesWorker()
        globalAccessor[globalAccessorKey(PRODUCT_NAME)].onmessage = ({
            data: {
                action,
                offers: {flights, hotels},
            },
        }) => {
            console.timeEnd('filter')
            this.STOP_FILTER()
            if (action === 'load') {
                this.SET_HOTEL_OFFERS(hotels)
                this.SET_FLIGHT_OFFERS(flights)
            } else if (action === FLIGHTS_PRODUCT_NAME) {
                if (this.filterActive) return
                this.SET_FLIGHT_OFFERS(flights)
            } else if (action === HOTELS_PRODUCT_NAME) {
                if (this.filterActive) return
                this.SET_HOTEL_OFFERS(hotels)
            }
            EventBus.$emit(FILTER_EVENT)
        }
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        packagesStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    async reload() {
        if (this.arrivalPoint.id && this.departurePoint.id)
            await this.loadPoints({departureCityId: this.departurePoint.id, arrivalCityId: this.arrivalPoint.id})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest())
        packagesStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest())
    }

    @Action
    stopSearch() {
        if (this.searchCTS) this.searchCTS.cancel()
        this.SET_SEARCH_CTS(null)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        try {
            await search.call(this, rq)
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }

    /**
     * For TBO supplier - hotel room offers refresh
     * @param supplierCode
     * @param cityCode
     * @param hotelCode
     * @param offerId
     * @returns {Promise<void>}
     */
    @Action({rawError: true})
    async updatePackageOffers({supplierCode, cityCode, hotelCode, offerId}) {
        try {
            this.ADD_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode})
            const rs = await appInstance.$api.updatePackageOffers.put({
                offersId: {
                    type: 'ACCOMMODATION',
                    offerId,
                },
                offerKey: packagesStore.searchResponse.offerKey,
            })
            const rooms = rs.offers.hotels.find(
                hotel =>
                    hotel.supplierCode === supplierCode && hotel.cityCode === cityCode && hotel.hotelCode === hotelCode
            ).rooms
            globalAccessor[globalAccessorKey(PRODUCT_NAME)].postMessage({
                action: 'refreshRooms',
                rooms,
                hotel: {supplierCode, cityCode, hotelCode},
            })
            this.UPDATE_ROOMS({supplierCode, cityCode, hotelCode, rooms})
        } catch (e) {
            return e
        } finally {
            this.REMOVE_UPDATE_ROOMS_ACTIVE({supplierCode, cityCode, hotelCode})
        }
    }

    @Action
    async load() {
        this.START_FILTER()
        console.time('filter')
        const offers = await appInstance.$localForage.getItem(PRODUCT_NAME)
        globalAccessor[globalAccessorKey(PRODUCT_NAME)].postMessage({
            action: 'load',
            offers,
            flightSortKey: packagesStore.flightSortKey,
            hotelSortKey: packagesStore.hotelSortKey,
            flightFilters: packagesStore.flightFilters,
            hotelFilters: packagesStore.hotelFilters,
        })
    }

    @Action
    async filterFlights() {
        this.START_FILTER()
        console.time('filter')
        globalAccessor[globalAccessorKey(PRODUCT_NAME)].postMessage({
            action: FLIGHTS_PRODUCT_NAME,
            flightFilters: packagesStore.flightFilters,
            flightSortKey: packagesStore.flightSortKey,
        })
    }

    @Action
    async filterHotels() {
        this.START_FILTER()
        console.time('filter')
        globalAccessor[globalAccessorKey(PRODUCT_NAME)].postMessage({
            action: HOTELS_PRODUCT_NAME,
            hotelFilters: packagesStore.hotelFilters,
            hotelSortKey: packagesStore.hotelSortKey,
        })
    }

    get searchActive() {
        return this.searchActiveCount > 0
    }

    get filterActive() {
        return this.filterActiveCount > 0
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {convertToCurrency, ...query} = searchRequest
            return {name: 'packages', query}
        }
    }

    get totalPrice() {
        return (room, flight, price) => ({
            amount:
                convertPrice(price).amount +
                convertPrice(room.deltaPrice).amount +
                convertPrice(flight.deltaPrice).amount,
            currency: persistentStore.currency,
        })
    }

    get updateRoomsActive() {
        return ({supplierCode, cityCode, hotelCode}) =>
            this.updateActiveOffers.findIndex(item => matches(item, {supplierCode, cityCode, hotelCode})) !== -1
    }
}
