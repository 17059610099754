import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {filters, searchRequest, searchResponse} from '@/utils/transfers/transfers-blank-states'
import {appInstance} from '@/utils/app-accessor'
import {persistentStore, runtimeStore, transfersRuntimeStore} from '@/store'
import Vue from 'vue'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {UTCToDate} from '@/utils/helpers'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {convertPrice} from '@/utils/filters'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            product: {offers: [], info: {}},
            info: {},
        }
    )
}

@Module({name: 'transfers', stateFactory: true, namespaced: true})
export default class TransfersStore extends ProductStoreBase {
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    filters = filters()
    sortFnName = 'priceAsc'
    searchExpirationTime = null
    prepareBookRequest = {
        departure: {},
        arrival: {},
    }
    prepareBookReturnTransferRequest = {
        departure: {},
        arrival: {},
    }
    prepareBookResponse = {}
    basket = []
    bookingAdditionalOptions = {}
    bookingExtraServices = []
    bookingAdditionalOptionsReturnTransfer = []
    bookingExtraServicesReturnTransfer = []

    @Mutation
    ADD_TO_BASKET({info, offerKey}) {
        this.basket = this.basket.filter(item => {
            const offer = item.product.offers.find(offer => offer.offerKey === item.offerKey)
            return isBefore(new Date(), UTCToDate(parseISO(offer.expirationTime)))
        })
        if (getBasketItem.call(this, offerKey).offerKey) return

        const product = transfersRuntimeStore.offers.find(
            product => product.offers.findIndex(offer => offer.offerKey === offerKey) !== -1
        )

        this.basket.push({
            offerKey,
            product,
            info,
            searchRequest: this.searchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.product.offers.find(offer => offer.offerKey === offerKey)
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_FILTER(data) {
        this.filters[data.key] = data.value
    }

    @Mutation
    SET_SORT(sort) {
        this.sortFnName = sort
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST(prepareBookReturnTransferRequest) {
        this.prepareBookReturnTransferRequest = prepareBookReturnTransferRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST_PROP({prop, val}) {
        Vue.set(this.prepareBookReturnTransferRequest, prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_LOCATION_PROP({pointType, prop, val}) {
        if (!this.prepareBookRequest[pointType]) {
            this.prepareBookRequest[pointType] = {}
        }
        Vue.set(this.prepareBookRequest[pointType], prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RETURN_TRANSFER_LOCATION_PROP({pointType, prop, val}) {
        Vue.set(this.prepareBookReturnTransferRequest[pointType], prop, val)
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.filters = filters()
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.prepareBookRequest = {
            departure: {},
            arrival: {},
        }
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Action({rawError: true})
    async prepareBook({rq, isReturnTransfer}) {
        const request = {...rq}

        if (request.arrival?.type === 'hotel') {
            request.arrival = {
                type: request.arrival.type,
                hotelName: request.arrival.hotelName,
                hotelCode: request.arrival.hotelCode,
            }
        }

        if (request.departure?.type === 'hotel') {
            request.departure = {
                type: request.departure.type,
                hotelName: request.departure.hotelName,
                hotelCode: request.departure.hotelCode,
            }
        }

        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            //TODO API workaround - ignore params in API layer
            request.tourists?.forEach(tourist => {
                if (tourist.passport) {
                    delete tourist.passport
                }
            })
            this.SET_PREPARE_BOOK_REQUEST(request)

            let rs
            if (isReturnTransfer) {
                const {basketKey} = await appInstance.$api.basket.post()

                const transferRs = await appInstance.$api.prepareBookTransfer.put(basketKey, request)

                this.SET_PREPARE_BOOK_RETURN_TRANSFER_REQUEST({
                    ...this.prepareBookRequest,
                    ...this.prepareBookReturnTransferRequest,
                    extraServices: this.bookingExtraServicesReturnTransfer?.length
                        ? this.bookingExtraServicesReturnTransfer.map(extraService => {
                              return {
                                  serviceRPH: extraService.serviceRPH,
                                  quantity: extraService.quantity,
                              }
                          })
                        : undefined,
                })

                const transferReturnRequest = {...this.prepareBookReturnTransferRequest}

                /*if (transferReturnRequest.arrival.type === 'hotel') {
                    transferReturnRequest.arrival = {
                        type: transferReturnRequest.arrival.type,
                        hotelName: transferReturnRequest.arrival.name,
                        hotelCode: transferReturnRequest.arrival.hotelCode,
                    }
                }

                if (transferReturnRequest.departure.type === 'hotel') {
                    transferReturnRequest.departure = {
                        type: transferReturnRequest.departure.type,
                        hotelName: transferReturnRequest.departure.name,
                        hotelCode: transferReturnRequest.departure.hotelCode,
                    }
                }*/

                const transferReturnRS = await appInstance.$api.prepareBookTransfer.put(
                    basketKey,
                    transferReturnRequest
                )

                // rs = {...transferReturnRS.initResponse, basketKey}
                // rs.transferRs = {...transferRs.initResponse}
                // rs.transferReturnRs = {...transferReturnRS.initResponse}
                rs = {
                    prepareBookResponses: [transferRs.initResponse, transferReturnRS.initResponse],
                    basketKey,
                }
            } else {
                rs = await appInstance.$api.prepareBookTransfer.post(request)
            }
            this.SET_PREPARE_BOOK_RESPONSE(rs)
            return rs
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !transfersRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return !!this.searchResponse.product.length
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions = options
        }
    }

    @MutationAction({mutate: ['bookingAdditionalOptions', 'bookingExtraServices']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: {},
            bookingExtraServices: [],
        }
    }

    @Action
    async getExtraServiceAdditionalOptions(offerKey) {
        try {
            const res = await appInstance.$api.transferAdditionalOptions.get({offerKey})
            this.context.commit('SET_BOOKING_ADDITIONAL_OPTIONS', res)
        } catch (error) {
            console.error(error)
        }
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS_RETURN_TRANSFER(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptionsReturnTransfer = options
        }
    }

    @MutationAction({mutate: ['bookingAdditionalOptionsReturnTransfer', 'bookingExtraServicesReturnTransfer']})
    clearBookingAdditionalOptionsReturnTransfer() {
        return {
            bookingAdditionalOptionsReturnTransfer: [],
            bookingExtraServicesReturnTransfer: [],
        }
    }

    @Action
    async getExtraServiceAdditionalOptionsReturnTransfer(offerKey) {
        try {
            const res = await appInstance.$api.transferAdditionalOptions.get({offerKey})
            this.context.commit('SET_BOOKING_ADDITIONAL_OPTIONS_RETURN_TRANSFER', res)
        } catch (error) {
            console.error(error)
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE_RETURN_TRANSFER(service) {
        const index = this.bookingExtraServicesReturnTransfer.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServicesReturnTransfer, index, service)
        } else {
            this.bookingExtraServicesReturnTransfer.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE_RETURN_TRANSFER(type) {
        const index = this.bookingExtraServicesReturnTransfer.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServicesReturnTransfer.splice(index, 1)
        }
    }

    get extraPrice() {
        return (price, isReturnTransfer) => {
            const extraServices = isReturnTransfer ? this.bookingExtraServicesReturnTransfer : this.bookingExtraServices

            return extraServices
                .filter(service => !service.mandatory)
                .reduce((total, {price, quantity}) => {
                    const serviceOriginalCurrency = price.originalCurrency || price.currency
                    let amount = convertPrice(price).amount,
                        serviceOriginalAmount = price.originalCurrency ? price.originalAmount : price.amount
                    if (quantity) {
                        amount = amount * quantity
                        serviceOriginalAmount = serviceOriginalAmount * quantity
                    }
                    total.amount += amount
                    total.supplierPriceAmount += serviceOriginalAmount
                    if (total.originalCurrency) {
                        if (serviceOriginalCurrency === total.originalCurrency) {
                            total.originalAmount += serviceOriginalAmount
                        } else {
                            delete total.originalCurrency
                        }
                    }
                    return total
                }, price)
        }
    }

    get orderTotalPrice() {
        return ({price, currentPrice, supplierPrice}, prebooked, isReturnTransfer) => {
            const offerPrice = prebooked && currentPrice ? currentPrice : price,
                originalCurrency = offerPrice.originalCurrency || offerPrice.currency,
                originalAmount = offerPrice.originalAmount || offerPrice.amount

            const supplierPriceObj = supplierPrice?.originalAmount
                ? {amount: supplierPrice?.originalAmount, currency: supplierPrice?.originalCurrency}
                : {amount: supplierPrice?.amount, currency: supplierPrice?.currency}
            const convertedSupplierPrice = convertPrice(supplierPriceObj, persistentStore.getCurrency(originalCurrency))
            const convertedSupplierPriceOriginal = convertPrice(supplierPriceObj, originalCurrency)

            const totalOfferPrice = {
                amount: convertPrice(offerPrice).amount,
                currency: persistentStore.currency,
                originalAmount,
                originalCurrency,
                supplierPriceAmount: convertedSupplierPrice?.amount,
                supplierPriceOriginalCurrencyAmount: convertedSupplierPriceOriginal?.amount,
            }
            if (offerPrice.commission) convertPrice(offerPrice.commission).amount
            if (supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount) {
                totalOfferPrice.supplierPrice?.commission?.originalAmount || supplierPrice?.commission?.amount
            }
            if (prebooked) return totalOfferPrice
            return this.extraPrice(totalOfferPrice, isReturnTransfer)
        }
    }
}
