import Vue from 'vue'
import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {isAfter, isBefore, parseISO} from 'date-fns'
import {filters, searchRequest, searchResponse} from '@/utils/cars/cars-rent-blank-state'
import {UTCToDate} from '@/utils/helpers'
import {salesTermsRsTransformer} from '@/utils/api-helpers'
import {appInstance} from '@/utils/app-accessor'
import ProductStoreBase from '@/store/modules/productStoreBase'
import {carsRentRuntimeStore, runtimeStore} from '@/store'

function getBasketItem(offerKey) {
    return (
        this.basket.find(item => item.offerKey === offerKey) || {
            searchRequest: searchRequest(),
            product: {offer: {}, info: {}},
        }
    )
}

@Module({name: 'carsRent', stateFactory: true, namespaced: true})
export default class CarsRentStore extends ProductStoreBase {
    searchRequest = searchRequest()
    searchResponse = searchResponse()
    filters = filters()
    sortFnName = 'priceAsc'
    searchExpirationTime = null

    prepareBookRequest = {}
    prepareBookResponse = {}
    basket = []
    bookingAdditionalOptions = []
    bookingExtraServices = []

    pickUpLocation = {}
    dropOffLocation = {}

    @Mutation
    ADD_TO_BASKET({offerKey}) {
        this.basket = this.basket.filter(item => {
            const offer = item.product.offer
            return isBefore(new Date(), UTCToDate(parseISO(offer.expirationTime)))
        })
        if (getBasketItem.call(this, offerKey).offerKey) return

        const product = carsRentRuntimeStore.offers.find(product => product.offer.offerKey === offerKey)

        this.basket.push({
            offerKey,
            product,
            searchRequest: this.searchRequest,
        })
    }

    @Mutation
    REFRESH_BASKET_PRICE({offerKey, prepareBookResponse}) {
        const basketItem = getBasketItem.call(this, offerKey)
        if (!basketItem.offerKey) return
        const offer = basketItem.product.offer
        const {price} = salesTermsRsTransformer(prepareBookResponse.currentSalesTerms)
        Vue.set(offer, 'currentPrice', price)
    }

    @Mutation
    SET_SEARCH_REQUEST(rq) {
        this.searchRequest = rq
    }

    @Mutation
    RESET_FILTERS() {
        this.filters = filters()
    }

    @Mutation
    SET_FILTER({key, value}) {
        this.filters[key] = value
    }

    @Mutation
    SET_FILTERS(val) {
        this.filters = val
    }

    @Mutation
    SET_SORT(sort) {
        this.sortFnName = sort
    }

    @Mutation
    NEW_SEARCH(searchRequest) {
        this.searchRequest = searchRequest
        this.filters = filters()
    }

    @Mutation
    RESET() {
        this.searchRequest = searchRequest()
        this.pickUpLocation = {}
        this.dropOffLocation = {}
        this.prepareBookRequest = {}
    }

    @Mutation
    SET_PREPARE_BOOK_RESPONSE(prepareBookResponse) {
        this.prepareBookResponse = prepareBookResponse
    }

    @Mutation
    SET_SEARCH_EXPIRATION_TIME(date) {
        this.searchExpirationTime = date
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST(prepareBookRequest) {
        this.prepareBookRequest = prepareBookRequest
    }

    @Mutation
    SET_PREPARE_BOOK_REQUEST_PROP(data) {
        Vue.set(this.prepareBookRequest, data.prop, data.val)
    }

    @Mutation
    SET_BOOKING_ADDITIONAL_OPTIONS(options) {
        if (Object.keys(options).length) {
            this.bookingAdditionalOptions = options
        }
    }

    @Mutation
    SET_BOOKING_EXTRA_SERVICE(service) {
        const index = this.bookingExtraServices.findIndex(el => el.type === service.type)
        if (index !== -1) {
            Vue.set(this.bookingExtraServices, index, service)
        } else {
            this.bookingExtraServices.push(service)
        }
    }

    @Mutation
    DELETE_EXTRA_SERVICE(type) {
        const index = this.bookingExtraServices.findIndex(el => el.type === type)
        if (index !== -1) {
            this.bookingExtraServices.splice(index, 1)
        }
    }

    @MutationAction({mutate: ['bookingAdditionalOptions', 'bookingExtraServices']})
    clearBookingAdditionalOptions() {
        return {
            bookingAdditionalOptions: [],
            bookingExtraServices: [],
        }
    }

    @Mutation
    SET_PICK_UP_LOCATION(val) {
        this.pickUpLocation = val
    }

    @Mutation
    SET_DROP_OFF_LOCATION(val) {
        this.dropOffLocation = val
    }

    @Action
    async getExtraServiceAdditionalOptions(offerKey) {
        try {
            const res = await appInstance.$api.additionalCarRentOptions.get({offerKey})
            this.SET_BOOKING_ADDITIONAL_OPTIONS(res)
        } catch (error) {
            console.error(error)
        }
    }

    @Action({rawError: true})
    async prepareBook(req) {
        runtimeStore.SET_BOOKING_ACTIVE(true)
        try {
            this.SET_PREPARE_BOOK_REQUEST(req)
            const response = await appInstance.$api.prepareCarBook.post(req)
            this.SET_PREPARE_BOOK_RESPONSE(response)
            return response
        } finally {
            runtimeStore.SET_BOOKING_ACTIVE(false)
        }
    }

    get isOffersExpired() {
        return () =>
            this.hasOffers && !carsRentRuntimeStore.searchActive && isAfter(new Date(), this.searchExpirationTime)
    }

    get basketItem() {
        return offerKey => getBasketItem.call(this, offerKey)
    }

    get hasOffers() {
        return !!this.searchResponse.products.length
    }
}
