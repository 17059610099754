const middleware = {}

middleware['auth'] = require('../../src/middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authorization'] = require('../../src/middleware/authorization.js')
middleware['authorization'] = middleware['authorization'].default || middleware['authorization']

middleware['guest'] = require('../../src/middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['home'] = require('../../src/middleware/home.js')
middleware['home'] = middleware['home'].default || middleware['home']

middleware['members'] = require('../../src/middleware/members.js')
middleware['members'] = middleware['members'].default || middleware['members']

middleware['onlyRegistered'] = require('../../src/middleware/onlyRegistered.js')
middleware['onlyRegistered'] = middleware['onlyRegistered'].default || middleware['onlyRegistered']

middleware['orderAuth'] = require('../../src/middleware/orderAuth.js')
middleware['orderAuth'] = middleware['orderAuth'].default || middleware['orderAuth']

export default middleware
