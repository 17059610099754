import {addMinutes} from 'date-fns'
import isEqual from 'lodash/isEqual'

const sha256 = require('hash.js/lib/hash/sha/256')

const matches = (obj, source) =>
    Object.keys(source).length === Object.keys(source).length &&
    // eslint-disable-next-line no-prototype-builtins
    Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key])

function clone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

function hash(obj) {
    const str = JSON.stringify(obj)
    return hashStr(str)
}

function hashStr(str) {
    return sha256().update(str).digest('hex')
}

function interopDefault(promise) {
    return promise.then(m => m.default || m)
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

const later = (delay, value) => {
    let timer = 0
    let reject = null
    const promise = new Promise((resolve, _reject) => {
        reject = _reject
        timer = setTimeout(resolve, delay, value)
    })
    return {
        get promise() {
            return promise
        },
        cancel() {
            if (timer) {
                clearTimeout(timer)
                timer = 0
                reject()
                reject = null
            }
        },
    }
}

function clean(obj) {
    const propNames = Object.getOwnPropertyNames(obj)
    for (let i = 0; i < propNames.length; i++) {
        let propName = propNames[i]
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName]
        }
    }
}

function formatAxiosError(error) {
    const {url, method, params, headers, data} = error.config
    let errorResponse
    if (error.response) {
        if (error.response.data instanceof ArrayBuffer) {
            errorResponse = JSON.parse(Buffer.from(error.response.data).toString('utf8'))
        } else {
            errorResponse = typeof error.response.data === 'object' ? error.response.data : {msg: error.response.data}
        }
        errorResponse.status = error.response.status
    } else {
        errorResponse = {
            msg: error.message,
            status: error.code === 'ECONNABORTED' ? 504 : 500,
        }
    }
    errorResponse.request = {
        url,
        method,
        params,
        headers,
    }
    if (data) {
        errorResponse.request.data = data
    }
    return errorResponse
}

function downloadData(data, filename) {
    const blob = new Blob([data]),
        link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    document.e2e.documentLink = link
}

async function downloadDataAsync(data, filename) {
    return await fetch(data)
        .then(res => res.blob())
        .then(blob => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        })
}

function getOfferAirportName({airportCode, airportDescription: {airportName, cityName, countryName}}) {
    return `${airportName}, ${cityName}, ${countryName} (${airportCode})`
}

const chunkArray = (array, chunk_size) =>
    Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map(begin => array.slice(begin, begin + chunk_size))

const flipArray = arr => {
    const length = arr.length
    const result = {}
    for (let i = 0; i < length; i++) {
        result[arr[i]] = i
    }
    return result
}

const queryMapper = {
    cityCode: 'cC',
    hotelCode: 'hC',
    supplierCode: 'sC',
}

const alphabetMap = {
    a: '1',
    b: '2',
    c: '3',
    d: '4',
    e: '5',
    f: '6',
    g: '7',
    h: '8',
    i: '9',
    j: '1a',
    k: '2a',
    l: '3a',
    m: '4a',
    n: '5a',
    o: '6a',
    p: '7a',
    q: '8a',
    r: '9a',
    s: '1b',
    t: '2b',
    u: '3b',
    v: '4b',
    w: '5b',
    x: '6b',
    y: '7b',
    z: '8b',
    0: '1c',
    1: '2c',
    2: '3c',
    3: '4c',
    4: '5c',
    5: '6c',
    6: '7c',
    7: '8c',
    8: '9c',
    9: '1d',
}

function encodeSupplierCode(supplierCode) {
    let hash = '',
        index
    const length = supplierCode.length

    for (index = 0; index < length; index++) {
        if (supplierCode[index] === '.') {
            hash += '9b'
        } else {
            hash += alphabetMap[supplierCode[index]]
        }
    }

    return hash
}

function decodeSupplierCode(supplierCodeHash) {
    let supplierCode = '',
        index,
        symbol
    const length = supplierCodeHash.length
    for (index = 0; index < length; index++) {
        if (index === length - 1 || Number.isInteger(parseInt(supplierCodeHash[index + 1]))) {
            symbol = supplierCodeHash[index]
        } else {
            symbol = supplierCodeHash[index] + supplierCodeHash[index + 1]
            index++
        }
        if (symbol === '9b') {
            supplierCode += '.'
        } else {
            supplierCode += Object.keys(alphabetMap).find(key => alphabetMap[key] === symbol)
        }
    }
    return supplierCode
}

const arrayEquals = (array1, array2) => {
    const array2Sorted = array2.slice().sort()
    return (
        array1.length === array2.length &&
        array1
            .slice()
            .sort()
            .every((value, index) => value === array2Sorted[index])
    )
}

const dateToUTC = date => addMinutes(date, new Date().getTimezoneOffset())

const UTCToDate = utcDate => addMinutes(utcDate, -new Date().getTimezoneOffset())

function capitalizeTheFirstLetterOfEachWord(words) {
    return words.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())
}

function itineraryKey(b2cKey, orderId) {
    return hashStr(b2cKey + orderId)
}

function getServiceTypeIcon(serviceType) {
    switch (serviceType) {
        case 'ACCOMMODATION':
            return 'mdi-office-building'
        case 'FLIGHT':
            return 'mdi-airplane'
        case 'OWNCHARTER':
            return 'mdi-airplane-takeoff'
        case 'CARRENT':
            return 'mdi-car'
        case 'ACTIVITY':
        case 'EXCURSION':
            return 'mdi-lightning-bolt-outline'
        case 'TRANSFER':
            return 'mdi-bus'
        case 'EXTRASERVICE':
        case 'OWNEXTRASERVICE':
            return 'mdi-domain-plus'
        case 'PACKAGE_TOUR':
            return 'mdi-gift-outline'
        case 'DYNAMIC_PACKAGE':
            return 'mdi-wallet-travel'
        case 'TRAIN':
            return 'mdi-train'
        case 'INSURANCE':
            return 'mdi-file-document-outline'
        case 'CRUISE':
            return 'mdi-ferry'
        case 'OWNVISA':
            return 'mdi-credit-card-outline'
    }
}

const hotelNameToUrl = name => name.toLowerCase().trim().replace(/\s+/g, '-').replace(/\//g, '_')

const PHONE_REGEXP = /^[\d+\-()\s]*$/

export const blobToBase64 = blob =>
    new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => resolve(reader.result)
    })

export const thumbnailUrl = (url, size, fullUrl) => {
    if (url) {
        if (url.indexOf('sunhotels') !== -1 || url.indexOf('tboholidays') !== -1) return url
        return fullUrl(`/thumbnails/${size}/${url}`)
    }
}

export {
    clone,
    hash,
    hashStr,
    interopDefault,
    timeout,
    later,
    clean,
    matches,
    formatAxiosError,
    downloadData,
    downloadDataAsync,
    getOfferAirportName,
    chunkArray,
    flipArray,
    queryMapper,
    encodeSupplierCode,
    decodeSupplierCode,
    arrayEquals,
    dateToUTC,
    UTCToDate,
    capitalizeTheFirstLetterOfEachWord,
    isEqual,
    itineraryKey,
    getServiceTypeIcon,
    hotelNameToUrl,
    PHONE_REGEXP,
}
