import {Action, Module, Mutation, MutationAction} from 'vuex-module-decorators'
import {persistentStore, runtimeStore, transfersStore} from '@/store'
import {
    B2B_AUTH_EVENT,
    CHANGE_LOCALE_EVENT,
    CHANGE_PRICE_EVENT,
    EventBus,
    RESET,
    RESET_BOOKING_DATA,
    SEARCH_EVENT,
} from '@/utils/event-bus'
import {searchRequest, searchResponse} from '@/utils/transfers/transfers-blank-states'
import {appInstance} from '@/utils/app-accessor'
import CityHotelAutocomplete from '@/components/search/forms/CityHotelAutocomplete'
import TransfersWorker from 'worker-loader!@/filters/transfersWorker'
import {PRODUCT_NAME} from '@/utils/transfers/transfers-const'
import {onmessage} from '@/utils/worker-helpers'
import {newSearch, partialSearch} from '@/utils/store-helpers'
import ProductRuntimeBase from '@/store/modules/productRuntimeBase'

async function loadPoint(point, searchRequest) {
    try {
        let hotelName, place, cityId
        if (point === 'arrivalPoint') {
            hotelName = 'arrivalHotelName'
            place = 'arrivalPlace'
            cityId = 'arrivalCityId'
        } else {
            hotelName = 'departureHotelName'
            place = 'departurePlace'
            cityId = 'departureCityId'
        }
        if (searchRequest[hotelName]) {
            const {hotels} = await appInstance.$api.suggestHotels.get({
                pattern: searchRequest[hotelName],
                locationId: searchRequest[cityId],
                limit: 1,
            })
            const pointInstance = CityHotelAutocomplete.options.methods.mapHotels.call(this, hotels)[0]
            const {
                hotelDescriptionInfo: {latitude, longitude, address},
            } = await appInstance.$api.hotelInfo.get({hotelId: pointInstance.id})
            Object.assign(pointInstance, {latitude, longitude, address: address?.addressLine})
            return {[point]: pointInstance}
        } else if (searchRequest[place] === 'hotel') {
            const rq = {
                id: searchRequest[cityId],
                limitCities: 1,
            }
            const rs = await appInstance.$api.locations.get(rq)
            return {[point]: rs.cities[0]}
        } else if (!isNaN(searchRequest[place])) {
            const [
                trainStations,
                {
                    cities: [city],
                },
                cruisePorts,
            ] = await Promise.all([
                appInstance.$api.trainStations.get({
                    pattern: null,
                    limit: 0,
                    productType: 'TRANSFER',
                    locationId: searchRequest[cityId],
                }),
                appInstance.$api.locations.get({
                    id: searchRequest[cityId],
                    limitCities: 1,
                }),
                appInstance.$api.cruisePorts.get({
                    id: searchRequest[cityId],
                    limit: 1,
                }),
            ])
            const trainStation = trainStations.find(
                trainStation => trainStation.id === parseInt(searchRequest[place], 10)
            )
            const cruisePort = cruisePorts.find(port => port.id === parseInt(searchRequest[place], 10))

            if (trainStation) {
                Object.assign(trainStation, {
                    type: 'trainStation',
                    cityName: city.name,
                    countryName: city.countryName,
                    parentName: city.countryName,
                    countryId: city.countryId,
                })

                return {[point]: trainStation}
            }

            if (cruisePort) {
                Object.assign(cruisePort, {
                    type: 'cruisePort',
                    cityName: city.name,
                    countryName: city.countryName,
                    parentName: city.countryName,
                    countryId: city.countryId,
                })
                return {[point]: cruisePort}
            }
        } else {
            const rq = {
                iataCode: searchRequest[place],
                limit: 1,
            }
            // if (isStoreDataPersist(cookie, rq)) return
            const rs = await appInstance.$api.airports.get(rq)
            //TODO refactor with autocomplete
            rs.forEach(airport => {
                Object.assign(airport, {parentName: airport.countryName})
            })
            return {[point]: rs[0]}
        }
    } catch (e) {
        return {[point]: {}}
    }
}

@Module({name: 'transfersRuntime', stateFactory: true, namespaced: true})
export default class TransfersRuntimeStore extends ProductRuntimeBase {
    departurePoint = {}
    arrivalPoint = {}

    @Mutation
    SET_DEPARTURE_POINT(val) {
        this.departurePoint = val
    }

    @Mutation
    SET_ARRIVAL_POINT(val) {
        this.arrivalPoint = val
    }

    @Mutation
    RESET() {
        this.searchActiveCount = 0
    }

    @MutationAction({mutate: ['departurePoint']})
    async loadDeparturePoint(searchRequest) {
        return await loadPoint.call(this, 'departurePoint', searchRequest)
    }

    @MutationAction({mutate: ['arrivalPoint']})
    async loadArrivalPoint(searchRequest) {
        return await loadPoint.call(this, 'arrivalPoint', searchRequest)
    }

    @Action
    clientInit() {
        EventBus.$on(RESET, this.reset)
        EventBus.$on(RESET_BOOKING_DATA, this.reset)
        EventBus.$on(B2B_AUTH_EVENT, this.reset)
        EventBus.$on(CHANGE_PRICE_EVENT, this.changePrice)
        EventBus.$on(CHANGE_LOCALE_EVENT, this.reload)
        onmessage.call(this, PRODUCT_NAME, new TransfersWorker())
    }

    @Action
    async changePrice({offerKey, prepareBookResponse}) {
        transfersStore.REFRESH_BASKET_PRICE({offerKey, prepareBookResponse})
        persistentStore.REFRESH_CONDITIONS({offerKey, prepareBookResponse})
    }

    @Action
    reset() {
        newSearch.call(this, searchRequest(), searchResponse(), transfersStore)
        this.RESET()
        transfersStore.RESET()
    }

    @Action
    newSearch() {
        newSearch.call(this, searchRequest(), searchResponse(), transfersStore)
    }

    @Action
    async reload() {
        const promises = []
        promises.push(this.loadDeparturePoint(transfersStore.searchRequest))
        promises.push(this.loadArrivalPoint(transfersStore.searchRequest))

        await Promise.all(promises)
    }

    @Action({rawError: true})
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq, searchResponse(), transfersStore)
        EventBus.$emit(SEARCH_EVENT)
        try {
            // eslint-disable-next-line no-unused-vars
            const {arrivalHotelName, departureHotelName, ...searchRequest} = rq
            await partialSearch.call(this, searchRequest, PRODUCT_NAME, transfersStore, appInstance.$api.searchTransfer)
        } finally {
            this.STOP_SEARCH()
        }
    }

    get searchPageLink() {
        return searchRequest => {
            // eslint-disable-next-line no-unused-vars
            const {partialResponse, convertToCurrency, ...query} = searchRequest
            return {name: 'transfers', query}
        }
    }

    get infoPageLink() {
        return (product, searchRequest) => {
            const {query} = this.searchPageLink(searchRequest)
            query.productId = product.productId
            query.name = product.info.name
            return {name: 'transfer', query}
        }
    }

    get ownProduct() {
        return product => runtimeStore.ownProduct(product?.info?.supplierCode)
    }

    get offerMainImage() {
        return (offer, info) => {
            if (!info.images || !info.images.length) return {url: null}
            let mainImage = info.images.find(image => image.mainImage) || {url: null}
            if (!mainImage.url && info.images.length > 0) {
                mainImage = info.images[0]
            }
            if (mainImage.linkedEntityName && mainImage.linkedEntityName !== offer.info.description) {
                mainImage = info.images.find(image => image.linkedEntityName === offer.info.description) || mainImage
            }
            return mainImage
        }
    }

    get searchRequestFromQuery() {
        return query => {
            //TODO Need filter $route.query params
            const rq = Object.assign(searchRequest(), query)
            if (typeof rq.childrenAges === 'string') {
                rq.childrenAges = [parseInt(rq.childrenAges, 10)]
            } else {
                rq.childrenAges = rq.childrenAges.map(age => parseInt(age, 10))
            }
            rq.convertToCurrency = persistentStore.currency
            rq.adults = parseInt(rq.adults, 10)
            rq.departureCityId = parseInt(rq.departureCityId, 10)
            rq.departureCountryId = parseInt(rq.departureCountryId, 10)
            rq.arrivalCityId = parseInt(rq.arrivalCityId, 10)
            rq.arrivalCountryId = parseInt(rq.arrivalCountryId, 10)
            rq.privateTransfer = rq.privateTransfer === 'true' || rq.privateTransfer === true
            rq.sharedTransfer = rq.sharedTransfer === 'true' || rq.sharedTransfer === true
            if (rq.citizenshipId) {
                rq.citizenshipId = parseInt(rq.citizenshipId, 10)
            }
            delete rq.name
            return rq
        }
    }
}
