import Vue from 'vue'
export const EventBus = new Vue()
export const SEARCH_EVENT = 'SEARCH_EVENT'
export const FILTER_EVENT = 'FILTER_EVENT'
export const OFFER_ITEM_HOVER_EVENT = 'OFFER_ITEM_HOVER_EVENT'
export const OFFER_ITEM_LEAVE_EVENT = 'OFFER_ITEM_LEAVE_EVENT'
export const SHOW_ON_MAP_EVENT = 'SHOW_ON_MAP_EVENT'
export const LOGOUT_REDIRECT_EVENT = 'LOGOUT_REDIRECT_EVENT'
export const FOOTER_INTERSECT_EVENT = 'FOOTER_INTERSECT_EVENT'
export const SHOW_CONDITIONS_MODAL_EVENT = 'SHOW_CONDITIONS_MODAL_EVENT'
export const SHOW_HOTEL_CONDITIONS_INFO = 'SHOW_HOTEL_CONDITIONS_INFO'
export const SHOW_TRANSFER_CONDITIONS_INFO = 'SHOW_TRANSFER_CONDITIONS_INFO'
export const SHOW_CARS_CONDITIONS_INFO = 'SHOW_CARS_CONDITIONS_INFO'
export const SHOW_CARS_INFO_MODAL = 'SHOW_CARS_INFO_MODAL'
export const SHOW_FLIGHT_CONDITIONS_INFO = 'SHOW_FLIGHT_CONDITIONS_INFO'
export const SHOW_EXTRA_SERVICE_CONDITIONS_INFO = 'SHOW_EXTRA_SERVICE_CONDITIONS_INFO'
export const SHOW_ACTIVITY_CONDITIONS_INFO = 'SHOW_ACTIVITY_CONDITIONS_INFO'
export const SHOW_ROOM_INFO_MODAL_EVENT = 'SHOW_ROOM_INFO_MODAL_EVENT'
export const SHOW_MEAL_TYPE_INFO_EVENT = 'SHOW_MEAL_TYPE_INFO_EVENT'
export const FULL_SCREEN_MODE = 'FULL_SCREEN_MODE'
export const OFFER_EXPIRED_EVENT = 'OFFER_EXPIRED_EVENT'
export const CHANGE_TAB_MOBILE_EVENT = 'CHANGE_TAB_MOBILE_EVENT'
export const AUTH_EVENT = 'AUTH_EVENT'
export const SHOW_TRANSFER_INFO_MODAL = 'SHOW_TRANSFER_INFO_MODAL'
export const SHOW_ACTIVITIES_INFO_MODAL = 'SHOW_ACTIVITIES_INFO_MODAL'
export const SHOW_CRUISE_INFO_MODAL = 'SHOW_CRUISE_INFO_MODAL'
export const SHOW_CRUISE_OFFER_INFO_MODAL = 'SHOW_CRUISE_OFFER_INFO_MODAL'
export const SHOW_CRUISE_CONDITIONS_INFO = 'SHOW_CRUISE_CONDITIONS_INFO'
export const SHOW_FLIGHT_PRICE_DETAIL = 'SHOW_FLIGHT_PRICE_DETAIL'
export const CHANGE_LOCALE_EVENT = 'CHANGE_LOCALE_EVENT'
export const PACKAGE_HOTEL_SELECTED = 'PACKAGE_HOTEL_SELECTED'
export const PACKAGE_FLIGHT_SELECTED = 'PACKAGE_FLIGHT_SELECTED'
export const RESET = 'RESET'
export const RESET_BOOKING_DATA = 'RESET_BOOKING_DATA'
export const B2B_AUTH_EVENT = 'B2B_AUTH_EVENT'
export const SHOW_INVOICES_MODAL_EVENT = 'SHOW_INVOICES_MODAL_EVENT'
export const SHOW_MODIFY_MODAL_EVENT = 'SHOW_MODIFY_MODAL_EVENT'
export const SHOW_MODIFY_MANUAL_MODAL = 'SHOW_MODIFY_MANUAL_MODAL'
export const CHANGE_PRICE_EVENT = 'CHANGE_PRICE_EVENT'
export const SHOW_EXTRA_SERVICE_INFO_MODAL = 'SHOW_EXTRA_SERVICE_INFO_MODAL'
export const ISSUE_INVOICES_MODAL_EVENT = 'ISSUE_INVOICES_MODAL_EVENT'
export const PAY_SERVICE_EVENT = 'PAY_SERVICE_EVENT'
export const PAY_ORDER_EVENT = 'PAY_ORDER_EVENT'
export const SHOW_PASSPORT_MODAL_EVENT = 'SHOW_PASSPORT_MODAL_EVENT'
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS'
export const SHOW_LOYALTY_POINTS = 'SHOW_LOYALTY_POINTS'
export const SHOW_ADDITIONAL_FEES_MODAL = 'SHOW_ADDITIONAL_FEES_MODAL'
export const SAME_CONTACT_DATA_FOR_ALL = 'SAME_CONTACT_DATA_FOR_ALL'
export const SHOW_TOUR_HOTEL_INFO = 'SHOW_TOUR_HOTEL_INFO'
export const SHOW_TOUR_FLIGHT_INFO = 'SHOW_TOUR_FLIGHT_INFO'
export const SHOW_TOUR_TRANSFER_INFO = 'SHOW_TOUR_TRANSFER_INFO'
export const SHOW_TOUR_ACTIVITY_INFO = 'SHOW_TOUR_ACTIVITY_INFO'
export const SHOW_TOUR_EXTRA_SERVICE_INFO = 'SHOW_TOUR_EXTRA_SERVICE_INFO'
export const SHOW_TOUR_INSURANCE_INFO = 'SHOW_TOUR_INSURANCE_INFO'
export const SHOW_TOUR_CRUISE_INFO = 'SHOW_TOUR_CRUISE_INFO'
export const SHOW_TOUR_CAR_INFO = 'SHOW_TOUR_CAR_INFO'
export const SHOW_INSURANCE_INFO_MODAL = 'SHOW_INSURANCE_INFO_MODAL'
export const ORDER_SERVICE_BOOK = 'ORDER_SERVICE_BOOK'
export const ORDER_SERVICE_BOOKED = 'ORDER_SERVICE_BOOKED'
export const SHOW_INSURANCE_CONDITIONS_INFO = 'SHOW_INSURANCE_CONDITIONS_INFO'
export const PREPARE_BOOK = 'PREPARE_BOOK'
export const SHOW_SERVICE_DOCUMENTS_MODAL = 'SHOW_SERVICE_DOCUMENTS_MODAL'
export const SHOW_RESERVATION_STATUS_MODAL = 'SHOW_RESERVATION_STATUS_MODAL'
export const SHOW_TICKET_VOIDING_MODAL = 'SHOW_TICKET_VOIDING_MODAL'
export const SHOW_SEND_EMAIL_MODAL = 'SHOW_SEND_EMAIL_MODAL'
export const SHOW_SERVICE_NOTES_MODAL = 'SHOW_SERVICE_NOTES_MODAL'
export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION_MODAL'
export const SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL = 'SHOW_DUE_TO_CONFIRM_DATE_SERVICE_MODAL'
export const SHOW_CONTACT_DETAILS_MODAL = 'SHOW_CONTACT_DETAILS_MODAL'
