import {appInstance} from '@/utils/app-accessor'
import {postMessageLoad} from '@/utils/worker-helpers'
import {axiosInstance} from '@/utils/axios-accessor'
import {later} from '@/utils/helpers'
import {addSeconds} from 'date-fns'

export const partialSearchRequest = ({searchKey}) => ({searchKey})

export const initSliderFilter = (initialValue, selectedValue, responseValue, key, mutation) => {
    const value = selectedValue.slice()
    if (initialValue[0] === selectedValue[0] || selectedValue[0] === 0) {
        value[0] = responseValue[0]
    }
    if (initialValue[1] === selectedValue[1] || selectedValue[1] === Infinity) {
        value[1] = responseValue[1]
    }
    if (selectedValue[0] !== value[0] || selectedValue[1] !== value[1]) {
        mutation({key, value})
    }
}

export const setSliderFilter = (searchResponse, productStore, key) => {
    initSliderFilter(
        productStore.searchResponse.filters[key],
        productStore.filters[key],
        searchResponse.filters[key],
        key,
        productStore.SET_FILTER
    )
}

const setSearchResponse = (searchResponse, productName, productStore, srOffersKey, sliderFilters) => {
    if (productStore.searchResponse[srOffersKey].length !== searchResponse[srOffersKey].length) {
        sliderFilters.forEach(key => {
            setSliderFilter(searchResponse, productStore, key)
        })
        const offers = searchResponse[srOffersKey],
            sr = {...searchResponse}
        //TODO Change to offersCount with api transformer
        sr[srOffersKey] = new Array(offers.length)
        productStore.SET_SEARCH_RESPONSE(sr)
        appInstance.$localForage.setItem(productName, offers)
        postMessageLoad(productName, productStore, offers)
    }
}

export async function partialSearch(
    searchRequest,
    productName,
    productStore,
    apiProductSearch,
    srOffersKey = 'product',
    sliderFilters = ['price']
) {
    const {searchKey} = await apiProductSearch.get(searchRequest)
    let searchResponse
    do {
        try {
            const cancelTokenSource = axiosInstance.CancelToken.source()
            this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
            searchResponse = await appInstance.$api.partialSearchResults.get(
                {searchKey, product: productName},
                cancelTokenSource.token
            )
            setSearchResponse(searchResponse, productName, productStore, srOffersKey, sliderFilters)
            if (!searchResponse.processed) {
                this.SET_PARTIAL_SEARCH_TIMEOUT_INSTANCE(later(appInstance.$config.search.partialResponseInterval))
                await this.partialSearchTimeoutInstance.promise
            }
        } catch (e) {
            if (!e) {
                break
            } else if (e.status === 404) {
                //TODO Check 404 - searchKey has expired
                await partialSearch.call(
                    this,
                    searchRequest,
                    productName,
                    productStore,
                    apiProductSearch,
                    srOffersKey,
                    sliderFilters
                )
                return
            } else {
                throw e
            }
        }
    } while (!searchResponse.processed)
    if (searchResponse[srOffersKey].length) {
        productStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), appInstance.$config.search.offersLifetime))
    }
}

export async function search(
    searchRequest,
    productName,
    productStore,
    apiProductSearch,
    srOffersKey = 'product',
    sliderFilters = ['price']
) {
    const cancelTokenSource = axiosInstance.CancelToken.source()
    this.SET_PARTIAL_SEARCH_RESULTS_CTS(cancelTokenSource)
    //TODO Add cancel token to request
    const searchResponse = await apiProductSearch.get(searchRequest)
    setSearchResponse(searchResponse, productName, productStore, srOffersKey, sliderFilters)
    if (searchResponse[srOffersKey].length) {
        productStore.SET_SEARCH_EXPIRATION_TIME(addSeconds(new Date(), appInstance.$config.search.offersLifetime))
    }
}

export function newSearch(searchRequest, blankSearchResponse, productStore) {
    this.stopSearch()
    this.SET_OFFERS([])
    productStore.SET_SEARCH_RESPONSE(blankSearchResponse)
    productStore.NEW_SEARCH(searchRequest)
}
