import {axiosBaseInstance, axiosInstance} from '~/utils/axios-accessor'

import * as authorization from '~/api/authorization/authorization'
import * as getUserInfoByToken from '~/api/authorization/getUserInfoByToken'
import * as registration from '~/api/crmperson/registration'
import * as restorePassword from '~/api/crmperson/restorePassword'
import * as resetPassword from '~/api/crmperson/resetPassword'
import * as locations from '~/api/locations/locations'

import * as fillAccommodationBookingData from '~/api/accommodations/fillAccommodationBookingData'
import * as searchAccommodation from '~/api/accommodations/searchAccommodation'
import * as partialSearchResults from '~/api/search/partialSearchResults'
import * as suppliers from '~/api/search/suppliers'
import * as hotelInfo from '~/api/accommodations/hotelInfo'
import * as hotels from '~/api/accommodations/hotels'
import * as conditions from '~/api/cancellation/conditions'
import * as cancelService from '~/api/cancellation/cancelService'
import * as currentCancelInfo from '~/api/cancellation/currentCancelInfo'
import * as paymentOptions from '~/api/booking/paymentOptions'
import * as prepareBookSaveOrder from '~/api/booking/prepareBookSaveOrder'
import * as paymentMethods from '~/api/booking/paymentMethods'
import * as paymentByLoyaltyPoints from '~/api/booking/paymentByLoyaltyPoints'
import * as checkstatus from '~/api/booking/checkstatus'
import * as prepareAccommodationBook from '~/api/accommodations/prepareAccommodationBook'
import * as book from '~/api/booking/book'
import * as exchangeRates from '~/api/exchangeRates/exchangeRates'
import * as companies from '~/api/companies/companies'
import * as companyRegistration from '~/api/companies/companyRegistration'
import * as tariffDetails from '~/api/accommodations/tariffDetails'
import * as onlinePaymentsInitiate from '~/api/onlinePayments/initiate'
import * as onlinePaymentsComplete from '~/api/onlinePayments/complete'
import * as searchAccommodationResults from '~/api/accommodations/searchAccommodationResults'
import * as popularDestinations from '~/api/cms/popularDestinations'
import * as popularHotels from '~/api/cms/popularHotels'
import * as blog from '~/api/cms/blog'
import * as reviews from '~/api/cms/reviews'
import * as topTours from '~/api/cms/topTours'
import * as layoutSettings from '~/api/cms/layoutSettings'
import * as infoPage from '~/api/cms/infoPage'
import * as resort from '~/api/cms/resort'
import * as resorts from '~/api/cms/resorts'
import * as persons from '~/api/persons/persons'
import * as orders from '~/api/orders/orders'
import * as orderFxGainLossDetailedInfo from '~/api/orders/orderFxGainLossDetailedInfo'
import * as currentOrderStatuses from '~/api/orders/currentOrderStatuses'
import * as orderItinerary from '~/api/orders/orderItinerary'
import * as downloadOrders from '~/api/orders/downloadOrders'
import * as modifyStatus from '~/api/orders/modifyStatus'
import * as manualModification from '~/api/orders/manualModification'
import * as itineraryKey from '~/api/orders/itineraryKey'
import * as travellerData from '@/api/orders/travellerData'
import * as orderServices from '@/api/orders/orderServices'
import * as airports from '~/api/locations/airports'
import * as cruisePorts from '~/api/locations/cruisePorts'
import * as voucher from '~/api/documents/voucher'
import * as itinerary from '~/api/documents/itinerary'
import * as additionalOptions from '~/api/accommodations/additionalOptions'
import * as suggestHotels from '~/api/accommodations/suggestHotels'
import * as updateCrmPerson from '~/api/crmperson/update'
import * as restoreB2BUserPasswordWithApiKey from '~/api/persons/restoreB2BUserPasswordWithApiKey'
import * as resetB2BUserPasswordWithApiKey from '~/api/persons/resetB2BUserPasswordWithApiKey'
import * as notificationTemplates from '~/api/notificationTemplates/notificationTemplates'
import * as documentTemplates from '~/api/documentTemplates/documentTemplates'
import * as crmPersonPassport from '~/api/crmperson/passport'
import * as personPassport from '~/api/persons/passport'
import * as documents from '~/api/documents/documents'
import * as voidFlightTicket from '~/api/orders/voidFlightTicket'

import * as fillTransferBookingData from '~/api/transfers/fillTransferBookingData'
import * as searchTransfer from '~/api/transfers/searchTransfer'
import * as transferInfo from '~/api/transfers/transferInfo'
import * as prepareBookTransfer from '~/api/transfers/prepareBookTransfer'
import * as trainStations from '~/api/locations/trainStations'
import * as transferAdditionalOptions from '~/api/transfers/transferAdditionalOptions'

import * as searchActivities from '~/api/activities/searchActivities'
import * as prepareActivityBook from '~/api/activities/prepareActivityBook'
import * as activityInfo from '~/api/activities/activityInfo'
import * as corporatePolicyViolationCode from '~/api/corporatePolicyViolationCodes/corporatePolicyViolationCode'
import * as activityAdditionalOptions from '~/api/activities/activityAdditionalOptions'
import * as fillActivityBookingData from '~/api/activities/fillActivityBookingData'

import * as searchFlight from '~/api/flights/searchFlight'
import * as flightInfo from '~/api/flights/flightInfo'
import * as flightSeatsMap from '~/api/flights/flightSeatsMap'
import * as flightFareOptions from '~/api/flights/flightFareOptions'
import * as airlines from '~/api/flights/airlines'
import * as prepareFlightBook from '~/api/flights/prepareFlightBook'
import * as flightFares from '~/api/flights/flightFares'
import * as searchFlightSchedule from '~/api/flights/searchFlightSchedule'
import * as checkFlightPricesAndAvailability from '~/api/flights/checkFlightPricesAndAvailability'
import * as additionalFlightOptions from '~/api/flights/additionalFlightOptions'
import * as issueFlightTicket from '~/api/flights/issueFlightTicket'
import * as flightPreferences from '~/api/flights/flightPreferences'
import * as fillFlightBookingData from '~/api/flights/fillFlightBookingData'

import * as report from '~/api/reports/report'
import * as reportParameters from '~/api/reports/reportParameters'
import * as reportsList from '~/api/reports/reportsList'
import * as contractGroups from '~/api/contractGroups/contractGroups'
import * as downloadStatementOfAccount from '~/api/reports/downloadStatementOfAccount'

import * as packageTourInfo from '~/api/package/packageTourInfo'
import * as packageTours from '~/api/package/packageTours'
import * as packagePrograms from '~/api/package/packagePrograms'
import * as preparePackageTourBook from '~/api/package/preparePackageTourBook'
import * as searchPackageTours from '~/api/package/searchPackageTours'
import * as updatePackageTourPrice from '~/api/package/updatePackageTourPrice'

import * as invoices from '~/api/invoices/invoices'
import * as printInvoice from '~/api/invoices/printInvoice'
import * as invoiceIssueInfo from '~/api/invoices/invoiceIssueInfo'
import * as issueInvoice from '~/api/invoices/issueInvoice'
import * as invoicePaymentMethods from '~/api/invoices/invoicePaymentMethods'
import * as invoicePaymentByKey from '~/api/invoices/invoicePaymentByKey'
import * as manualInvoiceIssueInfo from '~/api/invoices/manualInvoiceIssueInfo'
import * as manualInvoicePaymentMethods from '~/api/invoices/manualInvoicePaymentMethods'
import * as issueManualInvoice from '~/api/invoices/issueManualInvoice'
import * as manualInvoices from '~/api/invoices/manualInvoices'
import * as manualInvoicePaymentByKey from '~/api/invoices/manualInvoicePaymentByKey'
import * as cancelManualInvoice from '@/api/invoices/cancelManualInvoice'

import * as contracts from '~/api/ownProducts/contracts'
import * as ownProductPaxes from '~/api/ownProducts/ownProductPaxes'
import * as touristBookingFields from '~/api/booking/touristBookingFields'
import * as productBookingFields from '~/api/booking/productBookingFields'

import * as prepareAccommodationModify from '~/api/accommodations/prepareAccommodationModify'
import * as modifyAccommodationService from '~/api/accommodations/modifyAccommodationService'

import * as basket from '~/api/basket/basket'

import * as searchOwnExtraService from '~/api/ownExtraServices/searchOwnExtraService'
import * as ownExtraServiceInfo from '~/api/ownExtraServices/ownExtraServiceInfo'
import * as prepareOwnExtraServiceBook from '~/api/ownExtraServices/prepareOwnExtraServiceBook'
import * as ownExtraServiceAdditionalOptions from '~/api/ownExtraServices/ownExtraServiceAdditionalOptions'

import * as availability from '~/api/ownProducts/availability'
import * as dailyRates from '~/api/accommodationContracts/dailyRates'
import * as prices from '~/api/ownProducts/prices'

import * as messages from '~/api/onlineChat/messages'
import * as message from '~/api/onlineChat/message'
import * as markReadCount from '~/api/onlineChat/markReadCount'

import * as searchCarRent from '~/api/carRent/searchCarRent'
import * as prepareCarBook from '~/api/carRent/prepareCarBook'
import * as additionalCarRentOptions from '~/api/carRent/additionalCarRentOptions'
import * as carRentInfo from '~/api/carRent/carRentInfo'
import * as carBookingData from '~/api/carRent/carBookingData'
import * as fillCarBookingData from '~/api/carRent/fillCarBookingData'
import * as pointInfo from '~/api/carRent/pointInfo'

import * as searchInsurance from '~/api/insurance/searchInsurance'
import * as prepareInsuranceBook from '~/api/insurance/prepareInsuranceBook'

import * as schedulerGroups from '~/api/scheduler/schedulerGroups'
import * as schedulerGroupDetails from '~/api/scheduler/schedulerGroupDetails'
import * as schedulerGroupPresence from '~/api/scheduler/schedulerGroupPresence'

import * as tips from '~/api/orders/tips'

import * as communications from '~/api/crmCommunications/communications.js'
import * as visaInfo from '~/api/visa/visaInfo'

import * as wishlist from '~/api/b2c/wishlist'
import * as mail from '~/api/b2c/mail'
import * as reservationReportMail from '~/api/b2c/reservationReportMail'
import * as atolCertificate from '~/api/b2c/atolCertificate'
import * as reservationsTableOptions from '~/api/b2c/reservationsTableOptions'
import * as customFilters from '~/api/b2c/customFilters'
import * as reservationsFiltersConfiguration from '~/api/b2c/reservationsFiltersConfiguration'
import * as customDocuments from '~/api/b2c/customDocuments'

import * as searchCruises from '~/api/cruises/searchCruises'
import * as cruiseInfo from '~/api/cruises/cruiseInfo'
import * as prepareBookCruise from '~/api/cruises/prepareBookCruise'
import * as cruiseAdditionalOptions from '~/api/cruises/cruiseAdditionalOptions'
import * as fillCruiseBookingData from '~/api/cruises/fillCruiseBookingData'

import * as addOns from '~/api/orders/addOns'
import * as confirmPrebooking from '~/api/orders/confirmPrebooking'

import * as transactions from '~/api/uploads/transactions'

//TODO Separate cms api from gpts api
import * as cmsAuth from '~/api/cms/cmsAuth'
import * as customTranslations from '~/api/cms/customTranslations'
import * as homePageSettings from '~/api/cms/homePageSettings'

//Google API
import * as geocode from '~/api/google/geocode'
import * as placeAutocomplete from '~/api/google/placeAutocomplete'

// openWeatherMap API
import * as weather from '~/api/openWeatherMap/weather'

import * as searchDynamicPackages from '~/api/package/searchDynamicPackages'
import * as prepareDynamicPackageBook from '~/api/package/prepareDynamicPackageBook'
import * as updatePackagePrice from '~/api/package/updatePackagePrice'
import * as updatePackageOffers from '~/api/package/updatePackageOffers'

import * as privateClients from '~/api/crmperson/privateClients'
import {downloadData} from '@/utils/helpers'
import {appInstance} from '@/utils/app-accessor'

const getFilename = response => {
    const disposition = response.headers['content-disposition']
    if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(disposition)
        if (matches != null && matches[1]) {
            return decodeURI(matches[1].replace(/['"]/g, '')).replace('UTF-8', '')
        }
    }
    return ''
}

//TODO Setup routerBase for /api and /cms proxy
const setProxyBase = url => (appInstance.$config.routerBase ? appInstance.$config.routerBase.slice(0, -1) + url : url)

async function getMethod(url, request = {}, cancelToken, configParams) {
    const config = {
        params: request,
        ...configParams,
    }
    if (cancelToken) {
        config.cancelToken = cancelToken
    }
    return await axiosInstance.$get(setProxyBase(url), config)
}

async function postMethod(url, request, config) {
    return await axiosInstance.$post(setProxyBase(url), request, config)
}

async function deleteMethod(url, request = {}) {
    const config = {
        data: request,
    }
    return await axiosInstance.delete(setProxyBase(url), config)
}

async function putMethod(url, request, config) {
    return (await axiosInstance.put(setProxyBase(url), request, config)).data
}

async function getFile(url, request, filename) {
    const rs = await axiosInstance.get(setProxyBase(url), {
        params: request,
        responseType: 'blob',
    })
    downloadData(rs.data, filename || getFilename(rs))
}

async function postFile(url, request, filename) {
    const rs = await axiosInstance.post(setProxyBase(url), request, {
        responseType: 'blob',
    })
    downloadData(rs.data, filename || getFilename(rs))
}

export async function getBase(url, params = {}, cancelToken) {
    return await axiosBaseInstance.$get(url, {
        params,
        ...(cancelToken && {cancelToken}),
    })
}

export {
    getMethod,
    postMethod,
    deleteMethod,
    putMethod,
    getFile,
    postFile,
    authorization,
    getUserInfoByToken,
    registration,
    restorePassword,
    locations,
    searchAccommodation,
    partialSearchResults,
    suppliers,
    hotelInfo,
    hotels,
    conditions,
    cancelService,
    currentCancelInfo,
    paymentOptions,
    prepareBookSaveOrder,
    paymentMethods,
    paymentByLoyaltyPoints,
    checkstatus,
    prepareAccommodationBook,
    book,
    exchangeRates,
    companies,
    companyRegistration,
    tariffDetails,
    onlinePaymentsInitiate,
    onlinePaymentsComplete,
    searchAccommodationResults,
    popularDestinations,
    popularHotels,
    layoutSettings,
    infoPage,
    resort,
    resorts,
    persons,
    orders,
    currentOrderStatuses,
    orderItinerary,
    downloadOrders,
    airports,
    cruisePorts,
    voucher,
    itinerary,
    additionalOptions,
    suggestHotels,
    updateCrmPerson,
    fillTransferBookingData,
    searchTransfer,
    transferInfo,
    prepareBookTransfer,
    searchActivities,
    prepareActivityBook,
    trainStations,
    activityInfo,
    restoreB2BUserPasswordWithApiKey,
    resetB2BUserPasswordWithApiKey,
    resetPassword,
    corporatePolicyViolationCode,
    notificationTemplates,
    documentTemplates,
    searchFlight,
    flightInfo,
    flightSeatsMap,
    flightFareOptions,
    airlines,
    prepareFlightBook,
    flightFares,
    cmsAuth,
    customTranslations,
    homePageSettings,
    searchDynamicPackages,
    prepareDynamicPackageBook,
    updatePackagePrice,
    updatePackageOffers,
    searchFlightSchedule,
    checkFlightPricesAndAvailability,
    additionalFlightOptions,
    privateClients,
    report,
    reportParameters,
    reportsList,
    blog,
    contractGroups,
    topTours,
    packageTourInfo,
    packageTours,
    packagePrograms,
    preparePackageTourBook,
    searchPackageTours,
    updatePackageTourPrice,
    invoices,
    printInvoice,
    invoiceIssueInfo,
    issueInvoice,
    invoicePaymentMethods,
    invoicePaymentByKey,
    manualInvoiceIssueInfo,
    manualInvoicePaymentByKey,
    issueManualInvoice,
    manualInvoices,
    manualInvoicePaymentMethods,
    cancelManualInvoice,
    contracts,
    ownProductPaxes,
    touristBookingFields,
    ownExtraServiceInfo,
    prepareAccommodationModify,
    modifyAccommodationService,
    searchOwnExtraService,
    prepareOwnExtraServiceBook,
    ownExtraServiceAdditionalOptions,
    issueFlightTicket,
    activityAdditionalOptions,
    transferAdditionalOptions,
    crmPersonPassport,
    personPassport,
    modifyStatus,
    itineraryKey,
    availability,
    dailyRates,
    prices,
    downloadStatementOfAccount,
    messages,
    message,
    markReadCount,
    flightPreferences,
    searchCarRent,
    carRentInfo,
    carBookingData,
    pointInfo,
    prepareCarBook,
    additionalCarRentOptions,
    geocode,
    placeAutocomplete,
    prepareInsuranceBook,
    searchInsurance,
    travellerData,
    manualModification,
    documents,
    voidFlightTicket,
    basket,
    weather,
    visaInfo,
    schedulerGroups,
    schedulerGroupDetails,
    schedulerGroupPresence,
    orderServices,
    tips,
    communications,
    wishlist,
    mail,
    reservationReportMail,
    atolCertificate,
    searchCruises,
    cruiseInfo,
    prepareBookCruise,
    cruiseAdditionalOptions,
    reservationsTableOptions,
    customFilters,
    reservationsFiltersConfiguration,
    fillAccommodationBookingData,
    fillActivityBookingData,
    fillFlightBookingData,
    fillCruiseBookingData,
    fillCarBookingData,
    customDocuments,
    addOns,
    confirmPrebooking,
    productBookingFields,
    reviews,
    orderFxGainLossDetailedInfo,
    transactions,
}
