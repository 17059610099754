export default function ({redirect, $config, $cookies, isDev}) {
    if ($config.routerBase && !isDev && !$config.showHome) {
        return redirect($config.host)
    }
    if ($cookies.get('companyType') === 'INTERNAL_SUPPLIER') {
        return redirect('/supplier')
    }
    if ($cookies.get('role') === 'to1l.guide' || $cookies.get('role') === 'to1l.driver') {
        return redirect('/scheduler')
    }
}
